import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {FirestoreService} from "../../../services/firestore.service";
import {ClientInContextService} from "../../../services/client-in-context.service";
import {AuthService} from "../../../services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";

@Component({
  selector: 'app-create-position-dialog',
  templateUrl: './create-position-dialog.component.html',
  styleUrls: ['./create-position-dialog.component.scss']
})
export class CreatePositionDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  description: string;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  tasksListSubscription: Subscription;
  allTasksList: any[];
  allBeaconsList: any[];
  beaconsListSubscription: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreatePositionDialogComponent>,
  @Inject(MAT_DIALOG_DATA) data) {

    this.description = data.description;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.tasksListSubscription = this.firestoreService.getAllUnarchivedTasksForClientId(this.selectedClientDocData?.id).subscribe((tasks) => {
        this.allTasksList = tasks
        .filter(task => task.type === 'TASK')
        .filter(task => {
          if (task.func) {
            return task.func === 'TASK';
          } else {
            return false;
          }
        })
        .filter(task => task.deviceTarget.includes('WATCH'))
        .sort((taskA: any, taskB: any) => {
          return taskA.name?.toLowerCase() < taskB.name?.toLowerCase() ? -1 : taskA.name?.toLowerCase() > taskB.name?.toLowerCase() ? 1 : 0;
        });
      });

      this.beaconsListSubscription = this.firestoreService.getAllUnarchivedNAssgndBeaconsForClientId(this.selectedClientDocData?.id, 'POSITION').subscribe((beaconsList) => {
        this.allBeaconsList = beaconsList?.sort((beaconA: any, beaconB: any) => {
          return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
        });
      });

    });
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.tasksListSubscription?.unsubscribe();
    this.beaconsListSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      taskIds: [[], [Validators.required]],
      beaconIds: [[], []],
      stickDuration: [0, [Validators.required]],
      rssiThreshold: [-46, [Validators.required]],
    });
  }

  async createPosition() {
    if (!this.form.valid) {
      this.openSnackBar('Please enter all mandatory fields', 'error');
      return;
    }

    if (this.form?.value?.name && (this.form?.value?.name?.trim() === '')) {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    const positionToCreate = this.form.value;
    positionToCreate.name = positionToCreate.name?.trim();

    let taskNames = [];
    if (positionToCreate.taskIds && Array.isArray(positionToCreate.taskIds) && (positionToCreate.taskIds.length > 0)) {
      taskNames = this.allTasksList.filter(task => positionToCreate.taskIds.includes(task.id)).map(task => task.name);
    }
    positionToCreate.taskNames = taskNames;


    let beaconNames = [];
    if (positionToCreate.beaconIds && Array.isArray(positionToCreate.beaconIds) && (positionToCreate.beaconIds.length > 0)) {
      beaconNames = this.allBeaconsList.filter(beacon => positionToCreate.beaconIds.includes(beacon.id)).map(beacon => beacon.name);
    }
    positionToCreate.beaconNames = beaconNames;

    try {
      await this.firestoreService.createPositionForClientId(positionToCreate, this.selectedClientDocData.id);
      this.dialogRef.close(this.form.value);
      this.openSnackBar(`Position '${positionToCreate.name}' created successfully.`, 'success');
      this.form.reset();
    } catch (error) {
      this.openSnackBar('Error in position creation:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}


