

<mat-toolbar  *ngIf="loggedInUserDocData && loggedInUserDocData.email !== 'info@jacoudijk.nl'">

  <h1 class="pr-2"><img width="135" height="50" src="assets/Opus%20Watch_Logo_Wit_L.svg"></h1>

  <div class="nav-items">
    <a class="action px-3 px-xl-4" routerLink="/dashboard/labor"
     routerLinkActive="active-link">{{'DASHBOARD.NAV.MENU.LABOR.TITLE.TEXT' |  translate}}</a>

  <!--<a class="action" routerLink="/dashboard/growth" style="margin-left: 2rem; margin-right: 1rem"
     routerLinkActive="active-link">Growth</a>
  -->
  <a class="action px-3 px-xl-4" routerLink="/dashboard/productivity"
     routerLinkActive="active-link">{{'DASHBOARD.NAV.MENU.PRODUCTIVITY.TITLE.TEXT' |  translate}} </a>

  <a class="action px-3 px-xl-4" routerLink="/dashboard/sessions"
     routerLinkActive="active-link">{{'DASHBOARD.NAV.MENU.SESSIONS.TITLE.TEXT' |  translate}} </a>

  <!--
  <a class="action" routerLink="/dashboard/observations" style="margin-left: 2rem; margin-right: 1rem"
     routerLinkActive="active-link">Observations</a>
  -->

  <a class="action px-3 px-xl-4" routerLink="/dashboard/registrations"
     routerLinkActive="active-link">{{'DASHBOARD.NAV.MENU.REGISTRATIONS.TITLE.TEXT' |  translate}} </a>

  <a class="action px-3 px-xl-4" routerLink="/trainings"
     routerLinkActive="active-link" *ngIf="this.loggedInUserDocData?.type === 'developer'">{{'DASHBOARD.NAV.MENU.TRAINING.TITLE.TEXT' |  translate}}</a>
  </div>

  <span class="central-stretchable-space flex-grow"></span>
  <mat-form-field appearance="outline" style="height: 3rem"
                  *ngIf="loggedInUserDocData && clientInContextDocData && (clientLocationsList?.length > 0) && locationDropDownIsVisible()">
    <mat-select panelClass="nav-bar-select" [ngModel]="selectedClientLocationId" placeholder="Select Location"
                style="margin-top: 0.2rem; height:1.2rem;color: white!important;" panelClass="mat-select-panel-client-list"
                (ngModelChange)="onClientLocationChange($event)">
      <mat-option value="-1" *ngIf="!locationDropDownIsDisabled()">{{'DASHBOARD.NAV.MENU.ALLLOCATIONS.LABEL.TEXT' |  translate}}</mat-option>
      <mat-option *ngFor="let location of clientLocationsList" [value]="location.id" style="color: white;">
        {{location.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" style="height: 3rem"
                  *ngIf="loggedInUserDocData && clientInContextDocData && (loggedInUserDocData?.clients?.length !== 1)">
    <mat-select panelClass="nav-bar-select" [ngModel]="selectedClientId" placeholder="Select Client"
    style="margin-top: 0.2rem; height:1.2rem;color: white!important;" panelClass="mat-select-panel-client-list"
                (ngModelChange)="onClientChange($event)">
      <mat-option *ngFor="let client of loggedInUserDocData.clients" [value]="client.clientId" style="color: white;">
        {{client.clientName}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field appearance="outline" *ngIf="!loggedInUserDocData">
    <mat-select [(ngModel)]="selectedClientId">
      <mat-label> {{'DASHBOARD.NAV.MENU.SELECTCLIENTS.LABEL.TEXT' |  translate}}</mat-label>
      <mat-option *ngFor="let client of loggedInUserDocData.clients" [value]="client.clientId">
        {{client.clientName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="mx-3 mx-xl-4" *ngIf="loggedInUserDocData">
  <mat-icon matTooltip="Open Clock App" class="material-icons-outlined" (click)="openClockWeb()" style="cursor:pointer;transform: scale(1)">timer</mat-icon>
  </div>
  <button mat-mini-fab [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <div class="username-circle">
      {{ getInitials(this.loggedInUserDocData?.name)}}
    </div>
</button>
  <!-- <a class="action" [matMenuTriggerFor]="menu" style="margin-left: 2rem;margin-right: 1rem;margin-top: 6px;cursor: pointer;" *ngIf="this.loggedInUserDocData">
    
  </a> -->
  <mat-menu #menu="matMenu" class="mat-menu-custom">
    <div class="user_info">
      <span>{{loggedInUserDocData.name}} <span>{{loggedInUserDocData.type === 'developer'? 'DEV': ''}}</span></span>
      <span>{{loggedInUserDocData.email}}</span>
    </div>
    <button mat-menu-item [routerLink]="['/settings/users']" style="margin-top: -0.3rem; margin-bottom: -0.3rem;" *ngIf="selectedClientDocData?.hasManagerOrAdminRole">{{'DASHBOARD.NAV.MENU.NEWACCOUNT.BUTTON.TEXT' |  translate}}</button>
   
    <button mat-menu-item [routerLink]="['/settings/userprofile']" style="margin-top: -0.3rem; margin-bottom: -0.3rem;" *ngIf="selectedClientDocData?.hasManagerOrAdminRole">{{'DASHBOARD.NAV.MENU.PROFILE.BUTTON.TEXT' |  translate}} </button>
    <button mat-menu-item [routerLink]="['/settings']" style="margin-top: -0.3rem; margin-bottom: -0.3rem;" *ngIf="selectedClientDocData?.hasManagerOrAdminRole">{{'DASHBOARD.NAV.MENU.SETTINGS.BUTTON.TEXT' |  translate}} </button>
    
    <button mat-menu-item [routerLink]="['/help']" style="margin-bottom: -0.3rem;">{{'DASHBOARD.NAV.MENU.HELP.BUTTON.TEXT' |  translate}}</button>
    
    <button mat-menu-item (click)="this.authService.signOut()" style="margin-top: -0.3rem; margin-bottom: -0.3rem;">{{'DASHBOARD.NAV.MENU.LOGOUT.BUTTON.TEXT' |  translate}}</button>
  </mat-menu>
</mat-toolbar>
<mat-toolbar *ngIf="loggedInUserDocData && loggedInUserDocData.email === 'info@jacoudijk.nl'">

  <h1><img width="135" height="50" src="assets/Opus%20Watch_Logo_Wit_L.svg"></h1>


  <a class="action" [matMenuTriggerFor]="menu" style="margin-right: 1rem;margin-top: 6px;cursor: pointer;margin-left: 80%;" *ngIf="this.loggedInUserDocData">
    <div class="username-circle">
      {{ getInitials(this.loggedInUserDocData?.name)}}
    </div>
  </a>
  <mat-menu #menu="matMenu" class="mat-menu-custom">
    <button mat-menu-item (click)="this.authService.signOut()" style="margin-top: -0.1rem; margin-bottom: -0.5rem;">Log Out</button>
  </mat-menu>
</mat-toolbar>
