import {AfterViewInit, Component, DestroyRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {TaskMainAttributes} from '../../common/interfaces/clock-interfaces';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../services/firestore.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ClientInContextService} from '../../services/client-in-context.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BreakpointService} from '../../services/breakpoint.service';
import {ConfirmationDialogComponent} from '../utility/confirmation-dialog/confirmation-dialog.component';
import {SNACKBAR_CLASSES} from '../../common/utils/utils';
import firebase from 'firebase/compat/app';
import {Timestamp} from 'firebase/firestore';
import {CreateTaskDialogComponent} from "./create-task-dialog/create-task-dialog.component";
import {EditTaskDialogComponent} from "./edit-task-dialog/edit-task-dialog.component";
import {ClientTaskGroupsComponent} from '../client-task-groups/client-task-groups.component';
import {ClientLabelsComponent} from '../client-labels/client-labels.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-client-tasks',
  templateUrl: './client-tasks.component.html',
  styleUrls: ['./client-tasks.component.scss',
    '../../common/styles/listing.scss',]
})

export class ClientTasksComponent implements OnDestroy, AfterViewInit {
  unArchivedVsArchived = 'unarchived';
  taskAttributesReadableMap: Map<string, string> = new Map([
    ['id', 'ID'],
    ['name', 'SETTINGS.TABLEHEAD.NAME.TITLE.TEXT'],
    ['targetDevices', 'SETTINGS.TABLEHEAD.TARGETDEVICES.TITLE.TEXT'],
    ['function', 'Function'],
    ['taskGroups', 'SETTINGS.TABLEHEAD.TASKGROUPS.TITLE.TEXT'],
    ['targetLocations', 'SETTINGS.TABLEHEAD.TARGETLOCATIONS.TITLE.TEXT'],
    ['labels', 'SETTINGS.TABLEHEAD.LABELS.TITLE.TEXT'],
    ['creationTimestamp', 'SETTINGS.TABLEHEAD.CREATEDAT.TITLE.TEXT'],
  ]);

  screenSize = 'default';

  columnsToDisplay: string[] = ['id', 'name', 'targetDevices', 'function', 'taskGroups', 'targetLocations', 'labels', 'creationTimestamp'];
  columnsHeadersToDisplay: string[] = [
    'name',
    'targetDevices',
    'function',
    'taskGroups',
    'targetLocations',
    'labels',
    'creationTimestamp',
    'Edit',
  ];
  dataSource: MatTableDataSource<TaskMainAttributes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  tasksList: any[];
  dateColumns: string[] = ['creationTimestamp'];
  datePipe = new DatePipe('en-US');
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  unarchivedTasksSubscription: Subscription;
  archivedTasksSubscription: Subscription;
  breakpointSubscription: Subscription;
  filterValue: string
  locationListSubscription: Subscription;
  allLocationsList: any[];

  constructor(public firestoreService: FirestoreService,
              public authService: AuthService,
              public route: ActivatedRoute,
              private dialog: MatDialog,
              private clientInContextService: ClientInContextService,
              private snackBar: MatSnackBar,
              private breakpointService: BreakpointService,
              private destroyRef: DestroyRef) {
    this.breakpointSubscription = this.breakpointService.screenSize$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(screenSize => this.screenSize = screenSize);
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
          this.loggedInUserDocData = userDocData;
      });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;

        this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((locationsList) => {
          this.allLocationsList = locationsList;
        });

      this.fetchUnarchivedTasks();
    });
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateTaskDialogComponent, dialogConfig);
  }

  openEditDialog(record) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      taskRecord: record
    };

    this.dialog.open(EditTaskDialogComponent, dialogConfig);
  }

  openTaskGroup() {
    this.dialog.open(ClientTaskGroupsComponent);
  }

  openLabels() {
    this.dialog.open(ClientLabelsComponent, {});
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.archivedTasksSubscription?.unsubscribe();
    this.unarchivedTasksSubscription?.unsubscribe();
    this.breakpointSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
  }

  async archiveTask(task) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive task '${task?.name ?? ''}' ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        task
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        try {
          await this.firestoreService.updateTaskByIdForClientId(value?.task?.id, this.selectedClientDocData?.id, {isArchived: true});
          this.openSnackBar(`Task '${value?.task?.name}' archived successfully`, 'success');
        } catch (error) {
          this.openSnackBar('Error in task archival: ' + error.message, 'error');
          console.log(error.message);
        }
      }
    });
  }

  async unarchiveTask(task) {
    try {
      await this.firestoreService.updateTaskByIdForClientId(task?.id, this.selectedClientDocData?.id, {isArchived: false});
      this.openSnackBar(`Task '${task?.name}' unarchived successfully`, 'success');
    } catch (error) {
      this.openSnackBar('Error in task archival: ' + error.message, 'error');
      console.log(error.message);
    }
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  toggleArchived() {
    if (this.areUnArchivedTasksShown()) {
      this.fetchUnarchivedTasks();
    } else {
      this.fetchAllArchivedTasks();
    }
  }

  fetchUnarchivedTasks() {
    this.tasksList = [];
    this.archivedTasksSubscription?.unsubscribe();
    this.unarchivedTasksSubscription = this.firestoreService.getAllUnarchivedTasksForClientId(this.selectedClientDocData.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((tasksList) => {
      tasksList = tasksList.filter(task => !task.isPauseTask);
      this.tasksList = tasksList.map((task) => {
        if (task.taskGroups && task.taskGroups.length > 0) {
          task.taskGroups = task.taskGroups.join(',');
        }
        if (task.func) {
          let formattedFnValue = task.func.replace(/_/g, ' ').toLowerCase();
          formattedFnValue = formattedFnValue.charAt(0).toUpperCase() + formattedFnValue.slice(1);
          task.function = formattedFnValue
        }
        if (task.locationIds && task.locationIds.length > 0) {
          task.targetLocations = this.allLocationsList.filter(loc => task.locationIds.includes(loc.id)).map(loc => loc.name).join(', ');
        } else {
          task.targetLocations = '';
        }
        for (const [key, value] of Object.entries(task)) {
          if (this.dateColumns.includes(key)) {
            const timeValue = value as Timestamp;
            task[key] = this.datePipe.transform(
              timeValue.toMillis(),
              'yyyy-MM-dd HH:mm'
            );
          }
        }
        const targetDevicesList = task.deviceTarget && Array.isArray(task.deviceTarget) && task.deviceTarget.length > 0 ?
          task.deviceTarget.map(tg => {
            switch (tg) {
              case 'CLOCK':
                return 'Clock';
              case 'WATCH':
                return 'Watch';
              case 'CLOCKWEB':
                return 'ClockWeb';
            }

          }) : [];
        task.targetDevices = targetDevicesList.join(', ');
        return task;
      });


      this.tasksList.sort((taskA: any, taskB: any) => {
        return taskA.name < taskB.name ? -1 : taskA.name > taskB.name ? 1 : 0;
      });
      this.dataSource = new MatTableDataSource(this.tasksList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.initiateFiltering();
    });
  }

  fetchAllArchivedTasks() {
    this.tasksList = [];
    this.unarchivedTasksSubscription?.unsubscribe();
    this.archivedTasksSubscription = this.firestoreService.getAllArchivedTasksForClientId(this.selectedClientDocData.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((tasksList) => {
      this.tasksList = tasksList.map((task) => {
        if (task.taskGroups && task.taskGroups.length > 0) {
          task.taskGroups = task.taskGroups.join(',');
        }
        if (task.locationIds && task.locationIds.length > 0) {
          task.targetLocations = this.allLocationsList.filter(loc => task.locationIds.includes(loc.id)).map(loc => loc.name).join(', ');
        } else {
          task.targetLocations = '';
        }
        for (const [key, value] of Object.entries(task)) {
          if (this.dateColumns.includes(key)) {
            const timeValue = value as Timestamp;
            task[key] = this.datePipe.transform(
              timeValue.toMillis(),
              'yyyy-MM-dd HH:mm'
            );
          }
        }
        task.targetDevices = task.deviceTarget && Array.isArray(task.deviceTarget) && task.deviceTarget.length > 0 ?
          task.deviceTarget.map(tg => tg.name) : '';
        return task;
      });

      this.tasksList.sort((taskA: any, taskB: any) => {
        return taskA.name < taskB.name ? -1 : taskA.name > taskB.name ? 1 : 0;
      });

      this.dataSource = new MatTableDataSource(this.tasksList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.initiateFiltering();
    });
  }

  initiateFiltering() {
    if (this.filterValue) {
      this.dataSource.filter = this.filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  areArchivedTasksShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedTasksShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }
}
