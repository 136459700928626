<div style="min-width: 400px;">
  <h2 mat-dialog-title> {{'SETTINGS.TASK.EDITTASK.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="editTaskForm" >
    <div *ngIf="taskRecordBeingEdited.isPauseTask" class="py-3">
      <span innerHTML="{{'SETTINGS.TASK.PAUSETASK.CONTENT.TEXT' | translate}}"></span>
    </div>
      <div class="row pb-4">
        <div class="col-lg-12">
          <mat-form-field class="w-100">
            <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
            <input matInput required formControlName="name">
          </mat-form-field>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> {{'SETTINGS.MODAL.TASKGROUPS.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="taskGroupIds" multiple>
              <mat-option *ngFor="let taskGroup of allTaskGroupsList" [value]="taskGroup.id">
                {{taskGroup.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <small class="clr-primary worker-group" (click)="openAddTaaskGroup()"> {{'SETTINGS.MODAL.ADDTASKGROUP.BUTTON.TEXT' | translate}}</small>
        </div>
        <div class="col-lg-6 ">
          <mat-form-field class="w-100">
            <mat-label>{{'SETTINGS.MODAL.LABELS.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="labelIds" multiple>
              <mat-option *ngFor="let label of labelsList" [value]="label.id">{{label.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <small class="clr-primary worker-group" (click)="openAddLabel()">{{'SETTINGS.MODAL.ADDLABEL.BUTTON.TEXT' | translate}}</small>

        </div>
      </div>
      <div class="row pb-4">
        <div class="col-lg-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> {{'SETTINGS.MODAL.TARGETLOCATIONS.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="locationIds" multiple>
              <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
                {{location.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-lg-12">
          <app-beacon-selection [beaconIds]="editTaskForm.get('beaconIds')" [allBeaconsList]="allBeaconsList"></app-beacon-selection>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> {{'SETTINGS.MODAL.TARGETDEVICE.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="deviceTarget" required multiple>
              <mat-option *ngFor="let targetDevice of allTargetDevicesList" [value]="targetDevice.id">
                {{targetDevice.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> {{'SETTINGS.MODAL.FUNCTION.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="func" required>
              <mat-option *ngFor="let funcObj of allFunctionsList" [value]="funcObj.id">
                {{funcObj.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-lg-12">
          <mat-form-field class="w-100">
            <mat-label>{{'SETTINGS.MODAL.EXTERNALID.LABEL.TEXT' | translate}}</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>
        </div>
      </div>
  </mat-dialog-content>

  <div>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateTask()" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
