import {Component, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {SNACKBAR_CLASSES} from 'src/app/common/utils/utils';
import {AuthService} from 'src/app/services/auth.service';
import {ClientInContextService} from 'src/app/services/client-in-context.service';
import {FirestoreService} from 'src/app/services/firestore.service';

@Component({
  selector: 'app-client-sessions',
  templateUrl: './client-sessions.component.html',
  styleUrls: ['./client-sessions.component.scss']
})
export class ClientSessionsComponent implements OnDestroy {
  selectedClientDocData: any;
  autoArchiveShortSessions: boolean;
  private clientInContextServiceSubscription: Subscription;

  constructor(private firestoreService: FirestoreService,
              private clientInContextService: ClientInContextService,
              private authService: AuthService,
              private snackBar: MatSnackBar) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.autoArchiveShortSessions = selectedClientDocData.autoArchiveShortSessions ?? false;//set to false for backward compatibility
    });

  }


  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  async saveAutoArchiveSessionFlag() {
    if (this.selectedClientDocData.hasOwnProperty('autoArchiveShortSessions')) {//for backward compatibility
      if (this.autoArchiveShortSessions === this.selectedClientDocData.autoArchiveShortSessions) {
        this.openSnackBar('No changes to save!', 'error');
        return;
      }
    }

    try {
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, {
        autoArchiveShortSessions: this.autoArchiveShortSessions,
      });
      this.reloadClientInContext();
      this.openSnackBar('Setting for auto archiving sessions changed successfully!', 'success');
    } catch (error) {
      this.openSnackBar('Error in saving setting for auto archiving sessions:' + error.message, 'error');
    }
  }

  reloadClientInContext() {
    const clientSubscription = this.firestoreService.getClientById(this.selectedClientDocData.id).subscribe((clientFromDB) => {
      const loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
        (userDocData) => {
          if (userDocData) {
            const clientElementInUserDoc = userDocData?.clients.filter(client => client.clientId === clientFromDB.id);
            if (clientElementInUserDoc && clientElementInUserDoc.length > 0) {
              clientFromDB.role = clientElementInUserDoc[0]?.role;
            }
            this.clientInContextService.clientInContextSubject.next(clientFromDB);
            loggedInUserFromAuthServiceSubscription?.unsubscribe();
            clientSubscription?.unsubscribe();
          }
        });
    });
  }


  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
  }
}
