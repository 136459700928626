import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { FirestoreService } from "../../../services/firestore.service";
import { ClientInContextService } from "../../../services/client-in-context.service";
import { AuthService } from "../../../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { SNACKBAR_CLASSES } from "../../../common/utils/utils";
import { DatePipe } from '@angular/common';
import { CreateLabelDialogComponent } from '../../client-labels/create-label-dialog/create-label-dialog.component';

@Component({
  selector: 'app-create-variety-dialog',
  templateUrl: './create-variety-dialog.component.html',
  styleUrls: ['./create-variety-dialog.component.scss']
})
export class CreateVarietyDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  description: string;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  labelsList: any[];
  unarchivedLabelsSubscription: Subscription;
  datePipe = new DatePipe('en-US');

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreateVarietyDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.description = data.description;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
    });
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.unarchivedLabelsSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      labelIds: ['', []],
      performanceNorm: [null, []],
      externalId: ['', []],
    });

    this.fetchAllLabels()
  }

  async createVariety() {
    if (!this.form.valid) {
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    if (this.form?.value?.name && (this.form?.value?.name?.trim() === '')) {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    const varietyToCreate = this.form.value;
    varietyToCreate.name = varietyToCreate.name?.trim();

    varietyToCreate.performanceNorm = varietyToCreate.performanceNorm? +varietyToCreate.performanceNorm : null;

    if (varietyToCreate.labelIds && (varietyToCreate.labelIds.length > 0)) {
      varietyToCreate.labels = this.labelsList.filter(label => varietyToCreate.labelIds.includes(label.id)).map(label => label.name);
    } else {
      varietyToCreate.labelIds = null;
      varietyToCreate.labels = null;
    }

    varietyToCreate.externalId = varietyToCreate.externalId?.trim() ?? null;
    try {
      await this.firestoreService.createVarietyForClientId(varietyToCreate, this.selectedClientDocData.id);
      this.dialogRef.close(this.form.value);
      this.openSnackBar(`Variety '${varietyToCreate.name}' created successfully.`, 'success');
      this.form.reset();
    } catch (error) {
      this.openSnackBar('Error in variety creation:' + error.message, 'error');
      console.log(error.message);
    }
  }

  openAddLabel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateLabelDialogComponent, dialogConfig);
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  fetchAllLabels() {
    this.labelsList = [];
    this.unarchivedLabelsSubscription = this.firestoreService
      .getAllUnarchivedLabelsForClientId(this.selectedClientDocData.id)
      .subscribe((labelsList) => {
        this.labelsList = labelsList?.sort((labelA: any, labelB: any) => {
          return labelA.name?.toLowerCase() < labelB.name?.toLowerCase() ? -1 : labelA.name?.toLowerCase() > labelB.name?.toLowerCase() ? 1 : 0;
        });
      });
  }
}


