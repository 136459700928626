import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {AuthService} from '../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ClientInContextService} from "../../../services/client-in-context.service";
import { CreateWorkerGroupDialogComponent } from '../../client-worker-groups/create-worker-group-dialog/create-worker-group-dialog.component';
import { CreateLabelDialogComponent } from '../../client-labels/create-label-dialog/create-label-dialog.component';
import * as moment from "moment-timezone";
import {TIME_ZONE} from "../../../common/utils/time-utils";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { EUROPEAN_DATE_FORMATS } from 'src/app/common/utils/date-utils';

@Component({
  selector: 'app-create-worker-dialog',
  templateUrl: './edit-worker-dialog.component.html',
  styleUrls: ['./edit-worker-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: EUROPEAN_DATE_FORMATS}
  ]
})
export class EditWorkerDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  dataFromParent: any;
  allLocationsList: any[];
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  locationListSubscription: Subscription;
  allwgGroupsList = [];
  wgListSubscription: Subscription;
  labelsList: any[];
  unarchivedLabelsSubscription: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditWorkerDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.dataFromParent = data;
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(userDocData => {
      this.loggedInUserDocData = userDocData;
    });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.locationListSubscription = this.firestoreService
        .getAllLocationsForClientId(this.selectedClientDocData?.id)
        .subscribe((locationsList) => (this.allLocationsList = locationsList.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        })));

      this.wgListSubscription = this.firestoreService
        .getAllUnarchivedWorkerGroupsForClientId(this.selectedClientDocData?.id)
        .subscribe((wgList) => (this.allwgGroupsList = wgList.sort((wgA: any, wgB: any) => {
          return wgA.name?.toLowerCase() < wgB.name?.toLowerCase() ? -1 : wgA.name?.toLowerCase() > wgB.name?.toLowerCase() ? 1 : 0;
        })));
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [this.dataFromParent.name, [Validators.required]],
      workerCode: [this.dataFromParent.workerCode, []],
      workerGroupId: [this.dataFromParent.workerGroupId, []],
      labelIds: [this.dataFromParent.labelIds, []],
      isLeftHanded: [this.dataFromParent.isLeftHanded, []],
      locationIds: [this.dataFromParent.locationIds, []],
      hourlyRate: [+this.dataFromParent.hourlyRate, []],
      notes: [this.dataFromParent.notes, []],
      externalId: [this.dataFromParent.externalId, []],
      startTimestamp: [this.dataFromParent.startTimestamp? new Date(moment(this.dataFromParent.startTimestamp.toDate()).tz(TIME_ZONE).format('YYYY-MM-DD')) : null, []],
      endTimestamp: [this.dataFromParent.endTimestamp? new Date(moment(this.dataFromParent.endTimestamp.toDate()).tz(TIME_ZONE).format('YYYY-MM-DD')) : null, []],
    });

    this.form.controls.hourlyRate.valueChanges.subscribe(val => {
      if (!val) {
        return;
      }
      const valString = '' + val;
      if (valString?.indexOf('.') === -1) {
        return;
      }

      if (valString?.indexOf('.') !== -1) {
        if (valString?.endsWith('.')) {
          return;
        }
      }
      const valNumber = +valString;
      let decimalCount = 0;
      if ((valNumber % 1) !== 0) {
        decimalCount = valNumber.toString().split(".")[1].length;
      }

      const newVal = +valNumber.toFixed(decimalCount <= 2 ? decimalCount : 2);
      if (newVal !== +this.form.controls.hourlyRate.value) {
        this.form.patchValue({hourlyRate: newVal});
      }
    });
    this.fetchAllLabels()
  }

  get startTimestampSelected(): Date | undefined {
    return this.form?.get('startTimestamp')?.value;
  }

  set startTimestampSelected(value: Date | undefined) {
    this.form?.get('startTimestamp')?.setValue(value);
  }

  get endTimestampSelected(): Date | undefined {
    return this.form?.get('endTimestamp')?.value;
  }

  set endTimestampSelected(value: Date | undefined) {
    this.form?.get('endTimestamp')?.setValue(value);
  }


  async updateWorker() {
    if (this.dataFromParent.workerCode && (!/^([0-9]{2})$/.test(this.form?.value?.workerCode))) {
      this.openSnackBar('Please enter exactly 2 digits for worker code!', 'error');
      return;
    }

    if (this.form?.value?.name && (this.form?.value?.name?.trim() === '')) {
      this.openSnackBar('Empty or Invalid value entered for Name', 'error');
      return;
    }

    const workerDataToUpdate = this.form.value;
    workerDataToUpdate.name = workerDataToUpdate.name?.trim();
    workerDataToUpdate.externalId = workerDataToUpdate.externalId?.trim() ?? null;

    if (workerDataToUpdate.startTimestamp) {
      const rawStartTimestamp = moment(workerDataToUpdate.startTimestamp).format('YYYY-MM-DD') + ' 12:00';
      const startTimestampMoment = moment.tz(rawStartTimestamp, 'YYYY-MM-DD HH:mm', TIME_ZONE).startOf('day');
      workerDataToUpdate.startTimestamp = startTimestampMoment.toDate();
    } else {
      workerDataToUpdate.startTimestamp = null;
    }

    if (workerDataToUpdate.endTimestamp) {
      const rawEndTimestamp = moment(workerDataToUpdate.endTimestamp).format('YYYY-MM-DD') + ' 12:00';
      const endTimestampMoment = moment.tz(rawEndTimestamp, 'YYYY-MM-DD HH:mm', TIME_ZONE).endOf('day');
      workerDataToUpdate.endTimestamp = endTimestampMoment.toDate();
    } else {
      workerDataToUpdate.endTimestamp = null;
    }


    if (workerDataToUpdate.labelIds && (workerDataToUpdate.labelIds.length > 0)) {
      workerDataToUpdate.labels = this.labelsList.filter(label => workerDataToUpdate.labelIds.includes(label.id)).map(label => label.name);
    } else {
      workerDataToUpdate.labelIds = null;
      workerDataToUpdate.labels = null;
    }

    if (workerDataToUpdate.workerGroupId) {
      workerDataToUpdate.workerGroupName = this.allwgGroupsList.filter(wg => wg.id === workerDataToUpdate.workerGroupId)[0]?.name;
    } else {
      workerDataToUpdate.workerGroupId = null;
      workerDataToUpdate.workerGroupName = null;
    }

    try {
      workerDataToUpdate.id = this.dataFromParent.id;
      await this.firestoreService.updateWorkerForClientId(workerDataToUpdate, this.selectedClientDocData.id);
      this.openSnackBar('Changes have been saved', 'success');
      this.form.markAsPristine();
      this.dialogRef.close();
    } catch (error) {
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  openAddLabel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateLabelDialogComponent, dialogConfig);
  }

  close() {
    this.dialogRef.close();
  }

  openAddWorker(){
    this.dialog.open(CreateWorkerGroupDialogComponent);
  }
  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.wgListSubscription?.unsubscribe();
    this.unarchivedLabelsSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  fetchAllLabels() {
    this.labelsList = [];
    this.unarchivedLabelsSubscription = this.firestoreService
      .getAllUnarchivedLabelsForClientId(this.selectedClientDocData.id)
      .subscribe((labelsList) => {
        this.labelsList = labelsList.sort((labelA: any, labelB: any) => {
          return labelA.name < labelB.name ? -1 : labelA.name > labelB.name ? 1 : 0;
        });
      });
  }
}
