<div class="page-top-space">
  <mat-card class="page-main-card">
      <div class="page-action">
        <div class="tabs-selection">
          <nav mat-tab-nav-bar mat-align-tabs="center" style="margin-left: 3%; margin-right: 3%;margin-top: 0.5rem;"
        [tabPanel]="tabPanel">
          <a mat-tab-link label="TASKS" (click)="tabIndex = 0"
             [routerLink]="'/dashboard/registrations/tasks'"
             [active]="tabIndex === 0">
            {{'DASHBOARD.REGISTRATIONS.TASKS.TABS.LABEL.TEXT' | translate}}
          </a>
          <a mat-tab-link label="PRESENCES" (click)="tabIndex = 1"
             [routerLink]="'/dashboard/registrations/presences'"
             [active]="tabIndex === 1">
            {{'DASHBOARD.REGISTRATIONS.PRESENCES.TABS.LABEL.TEXT' | translate}}
          </a>
        </nav>
        </div>
        
      </div>
      
      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      <router-outlet></router-outlet>
  </mat-card>
</div>
