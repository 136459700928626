import { Injectable } from '@angular/core';
import { VideoHelpModalComponent } from '../common/components/video-help-modal/video-help-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class CommonModalService {

  constructor(public dialog: MatDialog) { }

  openDialog(videoId: any) {
    this.dialog.open(VideoHelpModalComponent, {
      data: {
        videoId: videoId,
      },
      panelClass:'video-helpModal-wrapper'
    });
  }
}
