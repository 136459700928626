import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDrawerMode, MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {AuthService} from "../../services/auth.service";
import {FirestoreService} from "../../services/firestore.service";
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {ClientInContextService} from "../../services/client-in-context.service";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from 'rxjs';
import { CommonModalService } from 'src/app/services/common-modal.service';

@Component({
  selector: 'app-sidenav-help',
  templateUrl: './sidenav-help.component.html',
  styleUrls: ['./sidenav-help.component.scss'],
})
export class SidenavHelpComponent implements OnInit {
  screenWidth: number;
  sideNavMode: MatDrawerMode = 'side';
  private links: any = [];

  @Input() helpContent = ``;
  private authUserSubscription: Subscription;
  URLP:any
  constructor(
    public authService: AuthService,
    public firestoreService: FirestoreService,
    public route: ActivatedRoute,
    private router: Router,
    public clientInContextService: ClientInContextService,
    private translate: TranslateService,
    private el: ElementRef, private renderer: Renderer2,
    private commonModal: CommonModalService
  ) {

    this.authUserSubscription = this.authService.loggedInUserFromAuthService$.subscribe((userDocData: any) => {
      translate.addLangs(['en', 'nl']);
      translate.setDefaultLang('en');
      if (userDocData.languageCode) {
        translate.use(userDocData.languageCode);
      } else {
        const browserLang = navigator.language;
        console.log('Translate will use language:' + browserLang.match(/en|nl/) ? browserLang : 'en');
        translate.use(browserLang.match(/en|nl/) ? browserLang : 'en');
      }
      this.authUserSubscription?.unsubscribe();

      this.firestoreService.getLanguageJSON('en').subscribe(enDS => translate.setTranslation('en', enDS.data()));
      this.firestoreService.getLanguageJSON('nl').subscribe(nlDS => translate.setTranslation('nl', nlDS.data()));

      this.router.events.subscribe((event) => {
       
        if (event instanceof NavigationEnd) {
          
          //console.log(JSON.stringify(event));
          // Hide loading indicator
          let url = event.urlAfterRedirects;
          url = url.substring(1, url.length);
          console.log('Loading help for:' + event.urlAfterRedirects);
          this.translate.get(url.toUpperCase().split('/').join('.') + '.SIDE_HELP.TEXT').subscribe(translation => this.helpContent = translation);
          this.onSideToggle()
        }
      });

    });
  }
  onSideToggle(){
    setTimeout(() => {
      let linkk = this.el.nativeElement.querySelector('#main-sidenav');
    linkk = linkk.querySelectorAll('a');
    linkk.forEach(link => {
      this.renderer.listen(link, 'click', (event: MouseEvent) => {
        this.handleClick(event);
      });
    });
    }, 300);
  }

  private handleClick(event: MouseEvent) {
    const target = event.target as any;
    
    if (target.hash && target.hash.startsWith('#video')) {
      this.handleBookmarkChange(event,target.hash)
    }
  }

  ngOnInit() {
    this.onResize();
    setTimeout(() => {
      
    }, 1000);
  }

  handleBookmarkChange(event:MouseEvent,targetHash:any) {
    const patternYTBookmark = targetHash.split(':');
    const patternVideoId = patternYTBookmark[1];
    const bookmark = location.hash.substring(1);
    event.preventDefault();
    if (patternVideoId) {
      const videoId = patternVideoId;
      if (videoId) {
        this.commonModal.openDialog(videoId)
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.sideNavMode = window.innerWidth < 1200 ? 'over' : 'side';
  }
}
