<section class="new-table-design-container mat-elevation-z8" tabindex="0" style="margin-top:4px;">
  <div class="controls-container filter-container">
    <button mat-flat-button color="primary" (click)="openCreateRegnDialog()"
      *ngIf="selectedClientDocData?.hasAdminRole">
      <mat-icon matTooltip="Create new task" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'DASHBOARD.REGISTRATIONS.TASKS.CREATE.BUTTON.TEXT' | translate}}</button>
    <div class="date-container justify-content-center">
      <app-date-selection></app-date-selection>
    </div>

    <button mat-icon-button (click)="downloadAsCSV('Registrations')" aria-label=""
      [matTooltip]="'DASHBOARD.REGISTRATIONS.TASKS.DOWNLOAD.TOOLTIP.TEXT' | translate">
      <mat-icon>download</mat-icon>

    </button>
    <mat-button-toggle-group class="archive-toggle" [hideSingleSelectionIndicator]="true" [(ngModel)]="selectedView" #group="matButtonToggleGroup">
      <mat-button-toggle value="listView" (click)="toggleArchived(false);" checked matTooltip="All items">
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="archiveView" (click)="toggleArchived(true);" matTooltip="Archived items">
        <mat-icon>archive</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

  </div>
  <div class="filter-string" *ngIf="filterString">{{filterString}}</div>
</section>

<div class="table-card">
  <section class="new-table-design-container table-body mat-elevation-z8 expandable-table" tabindex="0">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8">
      <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
        <th mat-header-cell class="table-header" *matHeaderCellDef>
          <div class="table-header-container">
            <div class="table-header-content-text">{{column.displayName | translate}}</div>
            <!--<div class="table-header-content-spacer"></div>-->
            <div class="table-header-content-menu">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                class="menu-button" *ngIf="column.showHeaderFilter" [class]="column.filtered ? 'filtered' : ''">
                <mat-icon>filter_list</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                <button mat-menu-item (click)="sortList(column.name,'asc')">
                  <mat-icon></mat-icon>
                  <span innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span>
                </button>
                <button mat-menu-item (click)="sortList(column.name,'desc')">
                  <mat-icon></mat-icon>
                  <span innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span>
                </button>
                <form class="example-form" (click)="$event.stopPropagation()">
                  <mat-form-field class="filter px-3" appearance="outline">
                    <mat-label>{{'DASHBOARD.TABLE.FILTERVALULE.LABEL.TEXT' | translate}}</mat-label>
                    <input type="text" placeholder="" aria-label="Filter Value" matInput
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="column.filterValue" [matAutocomplete]="auto"
                      (keyup)="headerFilter($event, column.name)" (focus)="headerFilter($event, column.name)" />
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of column.filterOptions" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
                <button mat-menu-item (click)="removeFilters()">
                  <mat-icon>filter_list_off</mat-icon>
                  <span> {{'DASHBOARD.TABLE.REMOVEALLFILTERS.LABEL.TEXT' | translate}}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{element[column.name]}}</td>
        <td mat-footer-cell *matFooterCellDef> {{column.footerValue}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper">
          <div class="align-items-center grid-control-column">
            <button mat-icon-button color="accent" (click)="openEditDialog($event, element)" matTooltip="Edit task"
              matTooltipClass="tooltip-class" matTooltipHideDelay="100" matTooltipPosition="below"
              [ngClass]="element.editButtonClass" style="margin-left: 14px;"
              [disabled]="!selectedClientDocData?.hasManagerOrAdminRole">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="accent" (click)="archiveRegistration($event, element)"
              class="listing-table-tools tdhover" matTooltip="Archive registration" matTooltipClass="tooltip-class"
              matTooltipHideDelay="100" matTooltipPosition="below" *ngIf="!element.isArchived">
              <mat-icon>archive</mat-icon>
            </button>
            <button mat-icon-button color="accent" (click)="unarchiveRegistration($event, element)"
              matTooltip="Unarchive registration" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
              matTooltipPosition="below" class="listing-table-tools tdhover" *ngIf="element.isArchived">
              <mat-icon>unarchive</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="checkColumn">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools">
          <div class="duration-error-icon">
            <mat-icon style="color: #d81159" class="material-symbols-outlined"
              matTooltip="Duration does not seem correct" *ngIf="element.isTooLong" matTooltipClass="tooltip-class"
              matTooltipHideDelay="100" matTooltipPosition="below">error</mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="getDisplayedCols().length">

          <div class="row-detail" [@detailExpand]=" element.id == expandedElement?.id ? 'expanded' : 'collapsed'">
            <div class="row-detail-description">
              <div class="row-detail-item">
                <strong> {{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.LOCATION.TITLE.TEXT' | translate}}:</strong>
                <span>{{element['locationName']}}</span>
              </div>
              <div class="row-detail-item">
                <strong> {{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.STEPCOUNTER.TITLE.TEXT' | translate}}:</strong>
                <span>{{element['stepCounter']}}</span>
              </div>
              <div class="row-detail-item">
                <strong>{{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.DEVICE.TITLE.TEXT' | translate}}:</strong>
                <span>{{element.deviceNumber}} ({{element['deviceId']}})</span>
              </div>
              <div class="row-detail-item">
                <strong> {{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.REGISTRATION.TITLE.TEXT' | translate}}: </strong>
                <span>{{element['id']}}</span>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getDisplayedCols(); sticky: true"></tr>
      <tr mat-row [class.hidden]="toggleTableChart !== 'table'" *matRowDef="let element; columns: getDisplayedCols();"
        [class.expanded-row]="applyExpandedClass(element)" (click)="expandRow(element)"></tr>
      <tr style="height: 0;" mat-row *matRowDef="let element; columns: ['expandedDetail']"
        class="row-detail expandable-row"></tr>
    </table>
  </section>
  <section class="new-table-design-container mat-elevation-z8" tabindex="0">
    <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons aria-label="Select page">
    </mat-paginator>
  </section>
</div>
