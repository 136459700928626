import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../../services/firestore.service';
import {ClientInContextService} from '../../../../services/client-in-context.service';
import {AuthService} from '../../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import {SNACKBAR_CLASSES} from '../../../../common/utils/utils';
import {ConfirmationDialogComponent} from '../../../utility/confirmation-dialog/confirmation-dialog.component';
import {roundTimeValue, TIME_FORMAT, TIME_ZONE} from '../../../../common/utils/time-utils';
import {v4 as uuidv4} from 'uuid';
import {NgForm, NgModel} from '@angular/forms';

@Component({
  selector: 'app-edit-presence-dialog',
  templateUrl: './edit-presence-dialog.component.html',
  styleUrls: ['./edit-presence-dialog.component.scss']
})
export class EditPresenceDialogComponent implements OnInit, OnDestroy {
  presenceLocationId: string;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  presenceDoc: any;
  breakRegns: any[] = [];
  breakRegnsDisplayed: any[] = [];
  originalPresenceDocument: any;
  allWorkersList: any[];
  workersListSubscription: Subscription;
  allTasksList: any[];
  allLocationsList: any[];
  locationListSubscription: Subscription;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  regnsSubscription: Subscription;
  beingSaved = false;
  startDayTaskRegn: any;
  endDayTaskRegn: any;
  taskRegnsOriginalList: any[];
  presenceListingRecord: any;
  isTimeChanged = false;
  startTimeRoundedHint: string;
  endTimeRoundedHint: string;
  @ViewChild('editForm') public editForm: NgForm;
  hoursWorkedCalculated: string;
  breakPaidCalculated: string;
  breakUnpaidCalculated: string;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditPresenceDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.presenceDoc = data.presenceRecord.presenceDoc;
    this.presenceLocationId = this.presenceDoc.locationId ?? null;
    this.presenceListingRecord = data.presenceRecord;
    //console.log(moment(this.regnRecordBeingEdited.startTs.toDate()).tz())
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
      }
    );

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.regnsSubscription = this.firestoreService.getAllUnarchivedTaskRegnsForPresence(this.presenceDoc).subscribe((taskRegns) => {
        this.taskRegnsOriginalList = taskRegns;

        let startDayTaskRegns = taskRegns.filter(taskRegn => (taskRegn.taskFunction === 'START_DAY')/* && !taskRegn.isRejected*/);
        if (startDayTaskRegns.length > 0) {
          if (this.presenceDoc.startTsPitId) {
            this.startDayTaskRegn = startDayTaskRegns.filter(taskRegn => taskRegn.id === this.presenceDoc.startTsPitId)[0];
          } else {
            // @ts-ignore
            startDayTaskRegns = startDayTaskRegns.sort((regnA: any, regnB: any) => moment(regnA.timestamp.toDate()) - moment(regnB.timestamp.toDate()));
            this.startDayTaskRegn = startDayTaskRegns[0];
          }
          this.startDayTaskRegn.startTime = moment(this.startDayTaskRegn.timestamp.toDate()).tz(TIME_ZONE).format('HH:mm');
          if (this.presenceDoc.startTimestampRounded) {
            this.startTimeRoundedHint = moment(this.presenceDoc.startTimestampRounded.toDate()).tz(TIME_ZONE).format('HH:mm');
          }
          this.startDayTaskRegn.isShown = true;
        } else {
          this.startDayTaskRegn = {
            id: null,
            isShown: false,
            locatonId: null,
            startTime: null,
          };
        }
        const endDayTaskRegns = taskRegns.filter(taskRegn => taskRegn.taskFunction === 'END_DAY');
        if (endDayTaskRegns.length > 0) {
          if (this.presenceDoc.endTsPitId) {
            this.endDayTaskRegn = endDayTaskRegns.filter(taskRegn => taskRegn.id === this.presenceDoc.endTsPitId)[0];
          } else {
            this.endDayTaskRegn = endDayTaskRegns[0];
          }
          this.endDayTaskRegn.endTime = moment(this.endDayTaskRegn.timestamp.toDate()).tz(TIME_ZONE).format('HH:mm');
          if (this.presenceDoc.endTimestampRounded) {
            this.endTimeRoundedHint = moment(this.presenceDoc.endTimestampRounded.toDate()).tz(TIME_ZONE).format('HH:mm');
            this.endDayTaskRegn.isShown = true;
          }
        } else {
          this.endDayTaskRegn = {
            id: null,
            isShown: false,
            locatonId: null,
            endTime: null,
          };
        }
        // @ts-ignore
        taskRegns = taskRegns.filter(task => task.taskType === 'BREAK');
        // @ts-ignore
        this.breakRegns = taskRegns.sort((regnA: any, regnB: any) => moment(regnA.startTimestamp.toDate()) - moment(regnB.startTimestamp.toDate()));
        this.breakRegnsDisplayed = this.breakRegns.map(breakRegn => {
          return {
            ...breakRegn,
            isDeleted: false,
            startTime: moment(breakRegn.startTimestamp.toDate()).tz(TIME_ZONE).format('HH:mm'),
            endTime: moment(breakRegn.endTimestamp.toDate()).tz(TIME_ZONE).format('HH:mm'),
            isPaid: !!breakRegn.taskFunction?.endsWith('_PAID')
          }
        })
        //console.log(JSON.stringify(this.breakRegns, null, 2));
      });
    });

    this.workersListSubscription = this.firestoreService.getUnArchivedWorkersForClientId(this.selectedClientDocData?.id).subscribe((workerList) => {
      this.allWorkersList = workerList;
    });
    this.locationListSubscription =
      this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => (this.allLocationsList = locationsList));
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


  ngOnDestroy(): void {
    this.workersListSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.regnsSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  async archivePresenceNRegns() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive this presence ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        try {
          await this.firestoreService.archiveAllTaskRegnsNPresence(this.taskRegnsOriginalList, this.presenceDoc.id, this.presenceDoc.clientId);
          this.openSnackBar(`Presence and it's registrations archived successfully`, 'success');
          this.dialogRef.close();
        } catch (error) {
          this.openSnackBar('Error in presence archival: ' + error.message, 'error');
          console.log(error.message);
        }
      }
    });
  }

  getBreakRegnsToDisplay() {
    return this.breakRegnsDisplayed.filter(regn => !regn.isDeleted);
  }

  deleteBreakRegn(regn, idx) {
    if (regn.id) {
      const deletedRegn: any = this.breakRegnsDisplayed.filter(br => br.id === regn.id);
      deletedRegn[0].isDeleted = true;
    } else {
      this.breakRegnsDisplayed.splice(idx, 1);
    }
  }

  addNewBreakRegn(regn, idx) {
    const index = this.breakRegnsDisplayed.findIndex(br => br.id === regn.id);
    const newBreakRegn = {
      uuid: uuidv4(),
      name: '',
      startTime: null,
      endTime: null,
      isPaid: false
    };
    this.breakRegnsDisplayed = [...this.breakRegnsDisplayed.slice(0, index + 1), newBreakRegn, ...this.breakRegnsDisplayed.slice(index + 1, this.breakRegnsDisplayed.length)];
  }

  addBreakRegnOnTop() {
    const newBreakRegn = {
      uuid: uuidv4(),
      name: '',
      startTime: null,
      endTime: null,
      isPaid: false
    };
    this.breakRegnsDisplayed.unshift(newBreakRegn);
  }

  cancelPresenceSave() {
    this.dialogRef.close();
  }

  async savePresenceNRegistrations() {
    this.regnsSubscription?.unsubscribe();
    this.beingSaved = true;
    if (!this.editForm.valid) {
      this.beingSaved = false;
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    for (const brk of this.breakRegnsDisplayed) {
      if (!brk.taskName && !brk.isDeleted) {
        this.beingSaved = false;
        this.openSnackBar('Please fill all mandatory fields', 'error');
        return;
      }
      if (!brk.startTime.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/) && !brk.isDeleted) {
        this.beingSaved = false;
        this.openSnackBar('Please fill all mandatory fields', 'error');
        return;
      }
      if (!brk.endTime.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/) && !brk.isDeleted) {
        this.beingSaved = false;
        this.openSnackBar('Please fill all mandatory fields', 'error');
        return;
      }
    }

    if (!this.startDayTaskRegn || !this.startDayTaskRegn.isShown) {
      this.beingSaved = false;
      this.openSnackBar('Start day creation is mandatory', 'error');
      return;
    }

    /*if (!this.endDayTaskRegn || !this.endDayTaskRegn.isShown) {
      this.beingSaved = false;
      this.openSnackBar('End day creation is mandatory', 'error');
      return;
    }*/

    try {
      const presenceUpdateObject: any = {
        updatedByUserId: this.loggedInUserDocData.id,
        updatedByUserName: this.loggedInUserDocData.name
      };
      if (this.startDayTaskRegn && this.startDayTaskRegn.isShown) {

        const startTimestamp = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +this.startDayTaskRegn.startTime.split(':')[0] ?? 0,
          minute: +this.startDayTaskRegn.startTime.split(':')[1] ?? 0,
        }).toDate();

        if (this.startDayTaskRegn.id) {
          await this.firestoreService.updateRegnBelowPresenceForClientId({
            timestamp: startTimestamp,
            id: this.startDayTaskRegn.id,
          }, this.selectedClientDocData.id, this.presenceDoc.id);
        } else {
          const startDayTaskRegnPITObj: any = {
            clientId: this.selectedClientDocData.id,
            clientName: this.selectedClientDocData.name ?? null,
            deviceId: null,
            deviceNumber: null,
            deviceType: 'browser-dashboard',
            isArchived: false,
            locationId: this.presenceLocationId,
            locationName: this.allLocationsList.filter(loc => loc.id === this.presenceLocationId)[0]?.name ?? null,
            taskFunction: 'START_DAY',
            createdFromDashboard: true,
            taskName: 'Start Day',
            taskType: 'PIT',
            timestamp: startTimestamp,
            workerId: this.presenceDoc.workerId,
            workerName: this.presenceDoc.workerName,
            workerGroupId: this.presenceDoc.workerGroupId ?? null,
            workerGroupName: this.presenceDoc.workerGroupName ?? null
          }
          const startDayTaskRegnRef = await this.firestoreService.createRegn(startDayTaskRegnPITObj, this.presenceDoc.id, this.selectedClientDocData.id);
          presenceUpdateObject.startTsPitId = startDayTaskRegnRef.id;
        }
        presenceUpdateObject.startTimestamp = startTimestamp;
      }
      for (const breakRegn of this.breakRegnsDisplayed) {
        if (breakRegn.isDeleted) {
          await this.firestoreService.deleteRegn(breakRegn.id, this.presenceDoc.id, this.selectedClientDocData.id);
          continue;
        }
        if (breakRegn.uuid && !breakRegn.id) {
          //create new break
          //const breakRegn: any = breakRegnsToSave.filter(brk => brk.uuid === breakRegn.uuid)[0];
          const startTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
            hour: +breakRegn.startTime.split(':')[0] ?? 0,
            minute: +breakRegn.startTime.split(':')[1] ?? 0,
          });
          const endTimestampMonent = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
            hour: +breakRegn.endTime.split(':')[0] ?? 0,
            minute: +breakRegn.endTime.split(':')[1] ?? 0,
          });
          const breakRegnObj: any = {};
          breakRegnObj.uuid = breakRegn.uuid;
          breakRegnObj.createdFromDashboard = true; //backend will ignore creation of breakRegn & only update durations if this flag is set
          breakRegnObj.startTimestamp = startTimestampMoment.toDate()
          breakRegnObj.endTimestamp = endTimestampMonent.toDate();
          breakRegnObj.durationTotal = endTimestampMonent.diff(startTimestampMoment, 'seconds');
          breakRegnObj.createdByUserId = this.loggedInUserDocData.id ?? null;
          breakRegnObj.creatdByUserName = this.loggedInUserDocData.name ?? null;
          breakRegnObj.workerId = this.presenceDoc.workerId ?? null;
          breakRegnObj.workerName = this.presenceDoc.workerName ?? null;
          breakRegnObj.workerGroupId = this.presenceDoc.workerGroupId ?? null;
          breakRegnObj.workerGroupName = this.presenceDoc.workerGroupName ?? null;
          breakRegnObj.isArchived = false;
          breakRegnObj.clientId = this.presenceDoc.clientId;
          breakRegnObj.clientName = this.selectedClientDocData.name ?? null;
          breakRegnObj.locationId = this.presenceDoc.locationId ?? null;
          breakRegnObj.locationName = this.presenceDoc.locationName ?? null;
          breakRegnObj.deviceType = this.presenceDoc.deviceType;
          breakRegnObj.taskFunction = breakRegn.isPaid ? 'BREAK_PAID' : 'BREAK_UNPAID';
          breakRegnObj.taskType = 'BREAK';
          breakRegnObj.taskName = breakRegn.taskName ?? null;
          await this.firestoreService.createRegn(breakRegnObj, this.presenceDoc.id, this.selectedClientDocData.id);
        }

        if (breakRegn.id) {
          //updateBreakRegn - TODO -- check how backend handles this update
          //const breakRegn: any = breakRegnsToSave.filter(brk => brk.id === breakRegn.id)[0];
          const startTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
            hour: +breakRegn.startTime.split(':')[0] ?? 0,
            minute: +breakRegn.startTime.split(':')[1] ?? 0,
          });
          const endTimestampMonent = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
            hour: +breakRegn.endTime.split(':')[0] ?? 0,
            minute: +breakRegn.endTime.split(':')[1] ?? 0,
          });
          const breakRegnObj: any = {};
          breakRegnObj.startTimestamp = startTimestampMoment.toDate()
          breakRegnObj.endTimestamp = endTimestampMonent.toDate();
          breakRegnObj.durationTotal = endTimestampMonent.diff(startTimestampMoment, 'seconds');
          breakRegnObj.updatedByUserId = this.loggedInUserDocData.id ?? null;
          breakRegnObj.updatedByUserName = this.loggedInUserDocData.name ?? null;
          breakRegnObj.locationId = this.presenceDoc.locationId ?? null;
          breakRegnObj.locationName = this.presenceDoc.locationName ?? null;
          breakRegnObj.taskFunction = breakRegn.isPaid ? 'BREAK_PAID' : 'BREAK_UNPAID';
          breakRegnObj.taskType = 'BREAK';
          breakRegnObj.taskName = breakRegn.taskName ?? null;
          breakRegnObj.id = breakRegn.id;
          await this.firestoreService.updateRegnBelowPresenceForClientId(breakRegnObj, this.selectedClientDocData.id, this.presenceDoc.id);
        }
      }
      if (this.endDayTaskRegn && this.endDayTaskRegn.isShown) {
        const endTimestamp = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +this.endDayTaskRegn.endTime.split(':')[0] ?? 0,
          minute: +this.endDayTaskRegn.endTime.split(':')[1] ?? 0,
        }).toDate();
        if (this.endDayTaskRegn.id) {
          await this.firestoreService.updateRegnBelowPresenceForClientId({
            timestamp: endTimestamp,
            id: this.endDayTaskRegn.id,
          }, this.selectedClientDocData.id, this.presenceDoc.id);
        } else {
          const endDayTaskRegnPITObj: any = {
            clientId: this.selectedClientDocData.id,
            clientName: this.selectedClientDocData.name ?? null,
            deviceId: null,
            deviceNumber: null,
            deviceType: 'browser-dashboard',
            isArchived: false,
            locationId: this.presenceLocationId,
            locationName: this.allLocationsList.filter(loc => loc.id === this.presenceLocationId)[0]?.name ?? null,
            taskFunction: 'END_DAY',
            createdFromDashboard: true,
            taskName: 'End Day',
            taskType: 'PIT',
            timestamp: endTimestamp,
            workerId: this.presenceDoc.workerId,
            workerName: this.presenceDoc.workerName,
            workerGroupId: this.presenceDoc.workerGroupId ?? null,
            workerGroupName: this.presenceDoc.workerGroupName ?? null
          }
          const endDayTaskRegnRef = await this.firestoreService.createRegn(endDayTaskRegnPITObj, this.presenceDoc.id, this.selectedClientDocData.id);
          presenceUpdateObject.endTsPitId = endDayTaskRegnRef.id;
        }
        presenceUpdateObject.endTimestamp = endTimestamp;
      }
      await this.firestoreService.updatePresenceIdForClientId(presenceUpdateObject, this.selectedClientDocData.id, this.presenceDoc.id);
      this.openSnackBar('Changes have been saved', 'success');
      this.dialogRef.close();
      this.beingSaved = false;
    } catch (error) {
      this.beingSaved = false;
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  showEndDayRegn() {
    this.endDayTaskRegn.isShown = true;
  }

  showStartDayRegn() {
    this.startDayTaskRegn.isShown = true;
  }

  getFormattedUpdatedTimestamp() {
    return moment(this.presenceDoc.updatedTimestamp.toDate()).tz(TIME_ZONE).format('DD MMM YYYY');
  }

  calculatePresenceDurations(fieldChanged) {
    //if (fieldChanged === 'startTime' || fieldChanged === 'endTime') {
    this.isTimeChanged = true;
    if (this.startDayTaskRegn.isShown && this.startDayTaskRegn.startTime && this.startDayTaskRegn.startTime.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/)) {
      const startTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
        hour: +this.startDayTaskRegn.startTime.split(':')[0] ?? 0,
        minute: +this.startDayTaskRegn.startTime.split(':')[1] ?? 0,
      });
      this.startTimeRoundedHint = roundTimeValue(startTimestampMoment, this.presenceDoc);
    } else {
      this.startTimeRoundedHint = null;
    }

    if (this.endDayTaskRegn.isShown && (this.endDayTaskRegn.endTime && this.endDayTaskRegn.endTime.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/))) {
      const endTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
        hour: +this.endDayTaskRegn.endTime.split(':')[0] ?? 0,
        minute: +this.endDayTaskRegn.endTime.split(':')[1] ?? 0,
      });
      this.endTimeRoundedHint = roundTimeValue(endTimestampMoment, this.presenceDoc);
    } else {
      this.endTimeRoundedHint = null;
    }

    if (this.startDayTaskRegn.isShown && this.endDayTaskRegn.isShown
      && this.startDayTaskRegn.startTime && this.startDayTaskRegn.startTime.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/)
      && (this.endDayTaskRegn.endTime && this.endDayTaskRegn.endTime.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/))) {
      let startTimestampMoment;
      let endTimestampMoment;
      if (this.startTimeRoundedHint && this.endTimeRoundedHint) {
        startTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +this.startTimeRoundedHint.split(':')[0] ?? 0,
          minute: +this.startTimeRoundedHint.split(':')[1] ?? 0,
        });

        endTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +this.endTimeRoundedHint.split(':')[0] ?? 0,
          minute: +this.endTimeRoundedHint.split(':')[1] ?? 0,
        });
      } else {
        startTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +this.startDayTaskRegn.startTime.split(':')[0] ?? 0,
          minute: +this.startDayTaskRegn.startTime.split(':')[1] ?? 0,
        });

        endTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +this.endDayTaskRegn.endTime.split(':')[0] ?? 0,
          minute: +this.endDayTaskRegn.endTime.split(':')[1] ?? 0,
        });
      }
      this.hoursWorkedCalculated = moment.duration(endTimestampMoment.diff(startTimestampMoment, 'seconds'), 'seconds').format(TIME_FORMAT);
    } else {
      this.hoursWorkedCalculated = '--:--';
    }
    //}
    //if (['breakStartTime', 'breakEndTime', 'breakIsPaid'].includes(fieldChanged)) {
    this.isTimeChanged = true;
    let arePaidBreakTimesCorrect = true;
    let areUnpaidBreakTimesCorrect = true;
    let breakPaidSecs = 0;
    let breakUnpaidSecs = 0;
    for (const brk of this.breakRegnsDisplayed) {
      if ((!brk.startTime?.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/) && !brk.isDeleted)) {
        if (brk.isPaid) {
          arePaidBreakTimesCorrect = false;
        } else {
          areUnpaidBreakTimesCorrect = false;
        }
      }

      if (!brk.endTime?.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/) && !brk.isDeleted) {
        if (!brk.isPaid) {
          areUnpaidBreakTimesCorrect = false;
        } else {
          arePaidBreakTimesCorrect = false;
        }
      }
      if (brk.isPaid && arePaidBreakTimesCorrect) {
        const startTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +brk.startTime.split(':')[0] ?? 0,
          minute: +brk.startTime.split(':')[1] ?? 0,
        });
        const endTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +brk.endTime.split(':')[0] ?? 0,
          minute: +brk.endTime.split(':')[1] ?? 0,
        });
        breakPaidSecs += endTimestampMoment.diff(startTimestampMoment, 'seconds');
      }

      if (!brk.isPaid && areUnpaidBreakTimesCorrect) {
        const startTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +brk.startTime.split(':')[0] ?? 0,
          minute: +brk.startTime.split(':')[1] ?? 0,
        });
        const endTimestampMoment = moment(this.presenceDoc.startTimestamp.toDate()).tz(TIME_ZONE).set({
          hour: +brk.endTime.split(':')[0] ?? 0,
          minute: +brk.endTime.split(':')[1] ?? 0,
        });
        breakUnpaidSecs += endTimestampMoment.diff(startTimestampMoment, 'seconds');
      }
    }

    if (!arePaidBreakTimesCorrect) {
      this.breakPaidCalculated = '--:--';
    } else {
      this.breakPaidCalculated = moment.duration(breakPaidSecs, 'seconds').format(TIME_FORMAT);
    }

    if (!areUnpaidBreakTimesCorrect) {
      this.breakUnpaidCalculated = '--:--';
    } else {
      this.breakUnpaidCalculated = moment.duration(breakUnpaidSecs, 'seconds').format(TIME_FORMAT);
    }
    //}
  }

  getHoursWorked() {
    if (!this.isTimeChanged) {
      return this.presenceListingRecord.hoursWorked ?? '00:00';
    } else {
      return this.hoursWorkedCalculated ?? '00:00';
    }
  }

  getBreaksPaid() {
    if (!this.isTimeChanged) {
      return this.presenceListingRecord.breakHoursPaid ?? '00:00';
    } else {
      return this.breakPaidCalculated ?? '00:00';
    }
  }

  getBreaksUnpaid() {
    if (!this.isTimeChanged) {
      return this.presenceListingRecord.breakHoursUnpaid ?? '00:00';
    } else {
      return this.breakUnpaidCalculated ?? '00:00';
    }
  }

}
