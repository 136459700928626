

<h2 mat-dialog-title>{{'DASHBOARD.LABOR.OVERVIEW.DETAILMODAL.TITLE.TEXT' | translate}}</h2>
<p class="px-4 cstm-color mb-0"><span class="pr-2">Name: </span>{{nestedTableData[0].workerName}}</p>
<mat-dialog-content class="table-styled">
    <table mat-table [dataSource]="nestedTableData" class="mat-elevation-z8">

        <ng-container
            matColumnDef="{{ column }}"
            *ngFor="let column of columnsHeadersToDisplayNested"
          >
            <th mat-header-cell *matHeaderCellDef>
                    {{workerAttributesReadableMap.get(column) | translate}}
            </th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <ng-container matColumnDef="red-dot">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="listing-table-tools">
              <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceComparedToPast && (element.workerPerformanceComparedToPast >= 0.1)" class="material-symbols-outlined success">
                trending_up
              </mat-icon>
              <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceComparedToPast && (element.workerPerformanceComparedToPast < -0.1)" class="material-symbols-outlined failure">
                trending_down
              </mat-icon>
              <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceComparedToPast &&
                (element.workerPerformanceComparedToPast < 0.1 && element.workerPerformanceComparedToPast >-0.1)" class="material-symbols-outlined equal">
                trending_flat
              </mat-icon>
              <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceRelativeToGroupPast > 0" class="material-symbols-outlined success">
                arrow_upward
              </mat-icon>
              <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceRelativeToGroupPast < 0" class="material-symbols-outlined failure">
                arrow_downward
              </mat-icon>

              <ng-template #workerPerfTooltip>
                <div style="background: #5b5f66; color: mintcream;padding:4px;border-radius:5px; text-align: center;font-size: 0.92rem;width:12rem !important;">
                  <div style="margin-bottom: 4px;font-weight: 500;">Performance indicator</div>
                  <span style="font-weight: 300;">Personal norm {{getPersonalNormPercentage(element)}}</span> <br/>
                  <span style="font-weight: 300;">Groups norm {{getGroupsNormPercentage(element)}}</span> <br/>
                </div>
              </ng-template>
              <span class="ml-2">{{element.isAnyNestedRowRed}}</span>

              <img [mtxTooltip]="redDotTooltip" ngSrc="assets/lightgray.ico" height="18" width="18" style="margin-top: 4px;" *ngIf="element.showRedButton">
              <ng-template #redDotTooltip>
                <div style="background: #5b5f66; color: mintcream;padding:4px;border-radius:5px; text-align: center;font-size: 0.92rem;width:12rem !important;">
                  <div style="margin-bottom: 4px;">Check if the session is correct</div>
                  <span >Gross Performance is {{element.grossPerformance}}</span> <br/>
                  <span style="">Net Performance is {{element.netPerformance}}</span> <br/>
                </div>
              </ng-template>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsHeadersToDisplay;"></tr>
      </table>
</mat-dialog-content>


