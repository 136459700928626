<div class="large-modal">
  <h2 mat-dialog-title>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.SUMMARY.TITLE.TEXT' |  translate}}</h2>
  <mat-dialog-content class="text-white">

    <form #createForm="ngForm">
      <div id='Summary'>
        <div class="row pb-4">
          <div class="col-lg-3">
            <mat-form-field class="w-100">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.WORKER.LABEL.TEXT' |  translate}}</mat-label>
              <mat-select [(ngModel)]="presenceWorkerId" name="workerId" (ngModelChange)="handleWorkerChange()"
                autofocus required>
                <mat-option *ngFor="let worker of allWorkersList" [value]="worker.id">
                  {{worker.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <div>
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.WORKERGROUP.LABEL.TEXT' |  translate}}</div>
              <div>{{presenceWorkerGroupName ?? '&nbsp;'}}</div>
            </div>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.LOCATION.LABEL.TEXT' |  translate}}</mat-label>
              <mat-select [(ngModel)]="presenceLocationId" name="locationId" autofocus required>
                <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
                  {{location.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row pb-4 pt-2">
          <div class="col-lg-3">
            <div class="date-input">
              <button mat-icon-button [matMenuTriggerFor]="dateMenu" aria-label="Example icon-button with a menu">
                <mat-icon>event</mat-icon>
              </button>
              <mat-menu #dateMenu="matMenu" class="date-menu customize_date">
                <mat-card appearance="outlined" class="demo-inline-calendar-card">
                  <mat-calendar [(selected)]="selectedDate" (selectedChange)="onDateChange()"></mat-calendar>
                </mat-card>
              </mat-menu>
              <span>{{selectedDate | date: 'd MMM y'}}</span>
            </div>
          </div>
          <div class="col-lg-3">
            <div>
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.HOURSWORKED.LABEL.TEXT' |  translate}}</div>
              <div>{{hoursWorked ?? ''}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div>
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.BREAKPAID.LABEL.TEXT' |  translate}}</div>
              <div>{{this.hoursBreaksPaid ?? '00:00'}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div>
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.BREAKUNPAID.LABEL.TEXT' |  translate}}</div>
              <div>{{this.hoursBreaksUnpaid ?? '00:00'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="presenceExistsForSelectedDate"><span innerHTML="{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ALREADYREGISTERED.CONTENT.TEXT' |  translate}}"></span></div>
      <div *ngIf="archivedPresenceExistsForSelectedDate"><span innerHTML="{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ARCHIVEDREGISTERED.CONTENT.TEXT' |  translate}}"></span></div>
      <div id='Details' class="mt-3">
        <h5 class="mb-4">Details</h5>
        <div *ngIf="startDayTaskRegn" class="gap-12 startTime-detail pb-4 d-flex align-items-center">

          <mat-icon>play_circle</mat-icon>
          <div class="f-grow">
            <mat-form-field>
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.STARTTIME.LABEL.TEXT' |  translate}}</mat-label>
              <input matInput type="time" required
                     [(ngModel)]="startDayTaskRegn.startTime"
                     (ngModelChange)="calculateDurations()"
                     name="startDayStartTime">
              <mat-hint>{{startTimeRoundedHint ?? ''}}</mat-hint>
            </mat-form-field>
          </div>
          <button mat-icon-button (click)="addBreakRegnOnTop()">
            <mat-icon matTooltip="Add break">add</mat-icon>
          </button>
        </div>
        <div *ngFor="let regn of getBreakRegnsToDisplay();let idx = index" class="pb-4">
          <mat-card class="d-flex flex-row align-items-center transparent-effect-card my-2">
            <mat-icon class="material-symbols-outlined">free_breakfast_outline</mat-icon>
            <mat-form-field style="margin-left: 1rem;">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.NAME.LABEL.TEXT' |  translate}}</mat-label>
              <input required matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.taskName"
                name="taskName">
            </mat-form-field>
            <mat-form-field style="margin-left: 1rem;">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.BREAKTIME.LABEL.TEXT' |  translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.breakTime"
                name="breakTime">
            </mat-form-field>
            <mat-form-field style="margin-left: 1rem;">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ENDTIME.LABEL.TEXT' |  translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.endTime"
                name="endTime">
            </mat-form-field>
            <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [(ngModel)]="regn.isPaid" class="custom-toggle-btn"
            name="isPaid" [ngModelOptions]="{standalone: true}" required aria-label="Font Style">
            <mat-button-toggle [value]="false" > {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEUNPAID.BUTTON.TEXT' |  translate}}</mat-button-toggle>
              <mat-button-toggle [value]="true" > {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEPAID.BUTTON.TEXT' |  translate}}</mat-button-toggle>

            </mat-button-toggle-group>
            <!-- <mat-slide-toggle class="mx-2" [(ngModel)]="regn.isPaid" name="isPaid" required>
              <mat-label>Paid</mat-label>
            </mat-slide-toggle> -->
            <div class="d-flex align-items-center">
              <button mat-icon-button matTooltip="Remove break" (click)="deleteBreakRegn(regn, idx)">
                <mat-icon>remove</mat-icon>
              </button>
              <button class="ml-1" mat-icon-button matTooltip="Add break" (click)="addNewBreakRegn(regn, idx)">
                <mat-icon>add</mat-icon>
              </button>


            </div>
            <div>

            </div>
          </mat-card>
        </div>
        <div *ngIf="endDayTaskRegn" class="gap-12 startTime-detail pb-4 d-flex align-items-center">
          <mat-icon>stop_circle</mat-icon>
          <mat-form-field>
            <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ENDTIME.LABEL.TEXT' |  translate}}</mat-label>
            <input matInput type="time" required
                   [(ngModel)]="endDayTaskRegn.endTime"
                   (ngModelChange)="calculateDurations()"
                   name="endDayEndTime">
            <mat-hint>{{endTimeRoundedHint ?? ''}}</mat-hint>
          </mat-form-field>
        </div>
      </div>


    </form>
  </mat-dialog-content>

  <div id='ButtonPanel'>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="primary" (click)="cancelPresenceSave()"
        style="margin-right: 20px;">{{'DASHBOARD.MODAL.CANCEL.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="presenceExistsForSelectedDate || beingSaved"
        (click)="createPresenceNRegistrations()">{{'DASHBOARD.MODAL.SAVE.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
