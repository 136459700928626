<mat-icon [matTooltip]="getTooltipForStreamingButton()" [inline]="true" (click)="toggleStreaming()"
          style="cursor: pointer; position: fixed;right: 3rem; bottom: 3rem; font-size: 3rem;"
          *ngIf="tableData?.length > 0">
  {{displayedIcon}}
</mat-icon>
<div class="inner-page-action">
  <app-date-selection></app-date-selection>
</div>
<div class="listing-container table-card">
  <div *ngIf="tableData?.length === 0" >
    <span innerHTML="{{'DASHBOARD.TABEL.SELECTEDDATE.NODATA.CONTENT.TEXT' | translate}}"></span>
  </div>
  <div  *ngIf="tableData?.length > 0" class="mat-elevation-z8" style="clear: both;" [style.display]="isLaborOverviewTableDisplayed()">
    <div class="table-body overflow-auto">
      <table
      mat-table
      [dataSource]="laborOverviewDataSource"
      multiTemplateDataRows
      matSort
      class="listing-table"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of columnsToDisplay"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{workerAttributesReadableMap.get(column) | translate}}
        </th>
        <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
        <mat-icon class="material-symbols-outlined">
          visibility
          </mat-icon>
      </ng-container>


      <ng-container matColumnDef="red-dot">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools">
          <mat-icon *ngIf="element.avgWorkerPerfRelToGroupPast > 0" class="material-symbols-outlined success">
            arrow_upward
          </mat-icon>
          <mat-icon *ngIf="element.avgWorkerPerfRelToGroupPast < 0" class="material-symbols-outlined failure">
            arrow_downward
          </mat-icon>
          <img [mtxTooltip]="redDotTooltip" ngSrc="assets/lightgray.ico" height="18" width="18" style="margin-top: 4px;" *ngIf="(element.isAnyNestedRowRed)">
          <ng-template #redDotTooltip>
            <div style="background: #5b5f66; color: mintcream;padding:4px;border-radius:5px; text-align: center;font-size: 0.92rem;width:12rem !important;">
              <div style="margin-bottom: 4px;">Check if the session is correct</div>
              <span style="">Gross Performance is {{element.grossPerformance}}</span> <br/>
              <span style="">Net Performance is {{element.netPerformance}}</span> <br/>
            </div>
          </ng-template>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsHeadersToDisplay"
        [class.expanded-row]="applyExpandedClass(element)"
        (click)="openDialog(element)"
      >

    </tr>

    </table>
    <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="25"
    [showFirstLastButtons]="true"
  ></mat-paginator>
    </div>


  </div>
</div>
