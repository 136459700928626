import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  nestedTable: any;
}

@Component({
  selector: 'app-grid-modal',
  templateUrl: './grid-modal.component.html',
  styleUrls: ['./grid-modal.component.scss']
})

export class GridModalComponent {
  columnsHeadersToDisplayNested: string[] = [
    'time',
    'rowNumber',
    'trolleyNumber',
    'varietyName',
    'netPerformance',
    'perfRatio',
    'amountPicked',
  ];
  columnsHeadersToDisplay: string[] = [
    'time',
    'rowNumber',
    'trolleyNumber',
    'varietyName',
    'netPerformance',
    'perfRatio',
    'amountPicked',
    'red-dot'
  ];
  nestedTableData: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.nestedTableData = data.nestedTable.nested.map(session => {
      return {
        ...session,
        trolleyNumber: session.trolleyId
      }
    })
    console.log('data is ', data)
  }

  workerAttributesReadableMap: Map<string, string> = new Map([
    ['time', 'DASHBOARD.TABLEHEAD.TIME.TITLE.TEXT'],
    ['rowNumber', 'DASHBOARD.TABLEHEAD.ROW.TITLE.TEXT'],
    ['trolleyNumber', 'DASHBOARD.TABLEHEAD.TROLLEY.TITLE.TEXT'],
    ['varietyName', 'DASHBOARD.TABLEHEAD.VARIETY.TITLE.TEXT'],
    ['netPerformance', 'DASHBOARD.TABLEHEAD.PERFORMANCE.TITLE.TEXT'],
    ['perfRatio', 'DASHBOARD.TABLEHEAD.PERFRATIO.TITLE.TEXT'],
    ['amountPicked', 'DASHBOARD.TABLEHEAD.AMOUNT.TITLE.TEXT'],
  ]);

  getPersonalNormPercentage(row) {
    if (row.workerPerformanceComparedToPast) {
      return ((+row.workerPerformanceComparedToPast.toFixed(2) * 100).toFixed(0)) + `%`;
    }
  }

  getGroupsNormPercentage(row) {
    if (row.workerPerformanceRelativeToGroupPast) {
      return ' (' + (row.varietyPerformanceWeightedAvg.toFixed(0)) + ') ' + ((+row.workerPerformanceRelativeToGroupPast.toFixed(2) * 100).toFixed(0)) + `%`;
    }
  }
}
