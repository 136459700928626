<div class="modal-width">
  <h2 mat-dialog-title>{{'SETTINGS.WORKERS.CREATEWORKER.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="form">
    <div class="row pb-3">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>Start date</mat-label>
          <input matInput [matDatepicker]="pickerStart" formControlName="startTimestamp" />
            <mat-datepicker-toggle *ngIf="!form.get('startTimestamp').value" matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <button *ngIf="form.get('startTimestamp').value" matSuffix mat-icon-button aria-label="Clear" (click)="form.get('startTimestamp').setValue(null)">
              <mat-icon>close</mat-icon>
            </button>
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>

        <!-- <div class="date-input">
          <button mat-icon-button [matMenuTriggerFor]="dateMenu" aria-label="Start date calendar menu">
            <mat-icon>event</mat-icon>
          </button>
          <mat-menu #dateMenu="matMenu" class="date-menu customize_date">
            <mat-card appearance="outlined" class="demo-inline-calendar-card">
              <mat-calendar [(selected)]="startTimestampSelected"></mat-calendar>
            </mat-card>
          </mat-menu>
          <span>{{startTimestampSelected | date: 'd MMM y'}}</span>
        </div> -->
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>End date</mat-label>
          <input matInput [matDatepicker]="pickerEnd" formControlName="endTimestamp" />
            <mat-datepicker-toggle *ngIf="!form.get('endTimestamp').value" matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <button *ngIf="form.get('endTimestamp').value" matSuffix mat-icon-button aria-label="Clear" (click)="form.get('endTimestamp').setValue(null)">
              <mat-icon>close</mat-icon>
            </button>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>

      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'SETTINGS.MODAL.LOCATIONS.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="locationIds" multiple>
            <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
              {{location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.WORKERGROUP.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="workerGroupId">
            <mat-option *ngFor="let workerGroup of allwgGroupsList" [value]="workerGroup.id">
              {{workerGroup.name}}
            </mat-option>
          </mat-select>

        </mat-form-field>
        <small class="clr-primary worker-group" (click)="openAddWorker()"> {{'SETTINGS.MODAL.ADDWORKERGROUP.BUTTON.TEXT'
          | translate}}</small>
      </div>
      <div class="col-lg-6 ">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.LABELS.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="labelIds" multiple>
            <mat-option *ngFor="let label of labelsList" [value]="label.id">{{label.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <small class="clr-primary worker-group" (click)="openAddLabel()">{{'SETTINGS.MODAL.ADDLABEL.BUTTON.TEXT' |
          translate}}</small>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'SETTINGS.MODAL.WORKERCODE.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="workerCode" maxlength="2" minlength="2">
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.HOURLYRATE.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="hourlyRate" type="number" placeholder="0.00" step="0.01"
            class="currency-input">
          <span matTextPrefix>€&nbsp;</span>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NOTES.LABEL.TEXT' | translate}}</mat-label>
          <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-slide-toggle class="left_handed_toggle" formControlName="isLeftHanded">
          <mat-label> {{'SETTINGS.MODAL.LEFTHANDED.LABEL.TEXT' | translate}}</mat-label>
        </mat-slide-toggle>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.EXTERNALID.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="externalId">
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' |
        translate}}</button>
      <button mat-raised-button color="primary" (click)="createWorker()" style="margin-left: 20px;">
        {{'SETTINGS.WORKERS.CREATEWORKER.TITLE.TEXT' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
