import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';

@Component({
  selector: 'app-edit-position-dialog',
  templateUrl: './edit-position-dialog.component.html',
  styleUrls: ['./edit-position-dialog.component.scss']
})
export class EditPositionDialogComponent implements OnInit, OnDestroy {
  editPositionForm: UntypedFormGroup;

  positionRecordBeingEdited: any;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  allBeaconsList: any[];
  beaconListSubscription: Subscription;
  tasksListSubscription: Subscription;
  allTasksList: any[];

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditPositionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.positionRecordBeingEdited = data.positionRecord;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.tasksListSubscription = this.firestoreService.getAllUnarchivedTasksForClientId(this.selectedClientDocData?.id).subscribe((tasks) => {
        this.allTasksList = tasks
        .filter(task => task.type === 'TASK')
        .filter(task => {
          if (task.func) {
            return task.func === 'TASK';
          } else {
            return false;
          }
        })
        .filter(task => task.deviceTarget.includes('WATCH'))
        .sort((taskA: any, taskB: any) => {
          return taskA.name?.toLowerCase() < taskB.name?.toLowerCase() ? -1 : taskA.name?.toLowerCase() > taskB.name?.toLowerCase() ? 1 : 0;
        });
      });

      //Get all beacons, then filter out 2 lists - assigned to this asset, unassigned beacons, then join the 2 lists to showin UI dropdown
      this.beaconListSubscription = this.firestoreService.getAllUnarchivedBeaconsByTypeForClientId(this.selectedClientDocData?.id, 'POSITION').subscribe((beaconsList) => {
        const beaconsAssignedToThisAsset = beaconsList.filter(beacon => beacon.assignedId === this.positionRecordBeingEdited.id);
        const nonAssignedBeaconsList = beaconsList.filter(beacon => !beacon.hasOwnProperty('assignedId') || !beacon.assignedId);
        const listsCombined = beaconsAssignedToThisAsset.concat(nonAssignedBeaconsList);
        this.allBeaconsList = listsCombined?.sort((beaconA: any, beaconB: any) => {
          return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
        });
      });
    });
  }

  ngOnInit() {
    this.editPositionForm = this.fb.group({
      name: [this.positionRecordBeingEdited.name, [Validators.required]],
      taskIds: [this.positionRecordBeingEdited.taskIds ?? [], [Validators.required]],
      beaconIds: [this.positionRecordBeingEdited.beaconIds ?? [], []],
      stickDuration: [this.positionRecordBeingEdited.stickDuration, [Validators.required]],
      rssiThreshold: [this.positionRecordBeingEdited.rssiThreshold, [Validators.required]],
    });
  }

  async updatePosition() {
    if (this.editPositionForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    const invalidFields = [];
    const controls = this.editPositionForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalidFields.push(name);
      }
    }

    if (!this.editPositionForm.valid) {
      if (invalidFields.includes('rssiThreshold')) {
        this.openSnackBar('RSSI Threshold should have a value between -127 and 0', 'error');
        return;
      }
      this.openSnackBar('Please enter all mandatory fields', 'error');
      return;
    }

    const positionDataToUpdate = this.editPositionForm.value;
    positionDataToUpdate.name = positionDataToUpdate.name?.trim();

    let taskNames = [];
    if (positionDataToUpdate.taskIds && Array.isArray(positionDataToUpdate.taskIds) && (positionDataToUpdate.taskIds.length > 0)) {
      taskNames = this.allTasksList.filter(task => positionDataToUpdate.taskIds.includes(task.id)).map(task => task.name);
    }
    positionDataToUpdate.taskNames = taskNames;


    let beaconNames = [];
    if (positionDataToUpdate.beaconIds && Array.isArray(positionDataToUpdate.beaconIds) && (positionDataToUpdate.beaconIds.length > 0)) {
      beaconNames = this.allBeaconsList.filter(beacon => positionDataToUpdate.beaconIds.includes(beacon.id)).map(beacon => beacon.name);
    }
    positionDataToUpdate.beaconNames = beaconNames;

    try {
      await this.firestoreService.updatePositionByIdForClientId(this.positionRecordBeingEdited.id, this.selectedClientDocData.id, positionDataToUpdate);
      this.openSnackBar('Changes have been saved', 'success');
      this.editPositionForm.markAsPristine();
      this.dialogRef.close();
    } catch (error) {
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.beaconListSubscription?.unsubscribe();
    this.tasksListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}

