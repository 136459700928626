<div style="min-width: 400px;">
  <h2 mat-dialog-title> {{'SETTINGS.POSTION.EDITPOSITION.TITLE.TEXT' | translate}}</h2>

  <mat-dialog-content [formGroup]="editPositionForm" style="text-align: center;">
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
      </div>
    </div>
   <div class="row pb-4">
    <div class="col-lg-12">
      <mat-form-field class="w-100">
        <mat-label> {{'SETTINGS.MODAL.TASKS.LABEL.TEXT' | translate}}</mat-label>
        <mat-select formControlName="taskIds" required multiple>
          <mat-option *ngFor="let task of allTasksList" [value]="task.id">
            {{task.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
   </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <app-beacon-selection [beaconIds]="editPositionForm.get('beaconIds')" [allBeaconsList]="allBeaconsList"></app-beacon-selection>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.STICKDURATION.LABEL.TEXT' | translate }}</mat-label>
          <input matInput formControlName="stickDuration" type="number" class="stick">
          <div matSuffix class="mat-suffix">sec</div>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.RSSITHRSHLD.LABEL.TEXT' | translate }}</mat-label>
          <input matInput required formControlName="rssiThreshold" type="number" placeholder="0.00" step="0.1" class="rssi" max="0" min="-127">
          <div matSuffix class="mat-suffix">dBm</div>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updatePosition()" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
