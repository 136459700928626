<div class="w-100">


  <div appearance="outlined" class="transparent-effect-card">
    <div style="display: flex; flex-direction: column;">
      <mat-card-title style="font-size: 1.2rem;margin-bottom: 0.4rem;font-weight: 500;">
        {{'SETTINGS.PRESENCES.BREAK.TITLE.TEXT' | translate}}</mat-card-title>
      <mat-card-title style="font-size: 1.15rem;margin-bottom: 0.4rem;font-weight: 400;">
        {{'SETTINGS.PRESENCES.DEFAULTBREAK.TITLE.TEXT' | translate}}</mat-card-title>
      <div style="display: flex;flex-direction: column;justify-content: left;">
        <mat-card-subtitle style="font-weight: 200;">
          <span>{{'SETTINGS.PRESENCES.DEFAULTBREAK.T_CONTENT.TEXT' | translate}}</span>
        </mat-card-subtitle>
        <div style="display: flex; flex-direction: row;justify-content: left;max-width: 70%;">
          <mat-form-field appearance="outline" style="margin-top: 0.8rem;flex-grow: 2">
            <mat-label>{{'SETTINGS.PRESENCES.NAME.LABEL.TEXT' | translate}}</mat-label>
            <input matInput [(ngModel)]="defaultBreak.name" required>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-top: 0.8rem; margin-left: 2.5rem;flex-grow: 1">
            <mat-label> {{'SETTINGS.PRESENCES.TYPE.LABEL.TEXT' | translate}}</mat-label>
            <mat-select [(ngModel)]="defaultBreak.breakTaskFunction">
              <mat-option value="BREAK_PAID"> {{'SETTINGS.PRESENCES.PAIDTOGGLE.LABEL.TEXT' | translate}}</mat-option>
              <mat-option value="BREAK_UNPAID"> {{'SETTINGS.PRESENCES.UNPAIDTOGGLE.LABEL.TEXT' |
                translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div style="font-size: 1.15rem;margin-bottom: 0.4rem;font-weight: 400;margin-top: 1.5rem;">
        {{'SETTINGS.PRESENCES.BREAKNOTIFICATION.TITLE.TEXT' | translate}}
      </div>
      <div style="display: flex; flex-direction: column;">
        <mat-card-subtitle style="font-weight: 200;margin-bottom: 0.5rem;">
          <span>{{'SETTINGS.PRESENCES.BREAKNOTIFICATION.T_CONTENT.TEXT' | translate}}</span>
        </mat-card-subtitle>
        <mat-slide-toggle [(ngModel)]="breakNotificationsWatchEnabled" style="margin-bottom: 1rem;margin-left:8px;">
          <mat-label style="font-size: 1rem;font-weight: 300;color: #ffffffb3;">
            {{'SETTINGS.PRESENCES.ENABLEBREAKNOTIFICATION.LABEL.TEXT' | translate}}
          </mat-label>
        </mat-slide-toggle>
      </div>
      <div style="font-size: 1.15rem;margin-bottom: 0.4rem;font-weight: 400;margin-top: 1.5rem;">
        {{'SETTINGS.PRESENCES.BREAKSCHEME.TITLE.TEXT' | translate}}
      </div>
      <div style="display: flex; flex-direction: row; gap: 16px;">
        <mat-card-subtitle style="font-weight: 200;">
          <span>{{'SETTINGS.PRESENCES.BREAKSCHEME.T_CONTENT.TEXT' | translate}}</span>
        </mat-card-subtitle>

      </div>
      <div class="d-flex justify-content-between my-3">
        <button mat-flat-button *ngIf="areUnArchivedBreakSchemesShown()" color="primary"
          (click)="createNewBreakScheme()">
          <mat-icon matTooltip="Add new break scheme" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
            playlist_add
          </mat-icon> {{'SETTINGS.PRESENCES.BREAKSCHEME.ADDNEW.BUTTON.TEXT' | translate}}</button>
        <span class="f-grow"></span>
        <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
          [(ngModel)]="unArchivedVsArchived">
          <mat-button-toggle value="unarchived" aria-label="Text align left">
            <mat-icon matTooltip="Show break schemes" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
              matTooltipPosition="below">list
            </mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="archived" aria-label="Text align center">
            <mat-icon matTooltip="Show archived break schemes" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
              matTooltipPosition="below">archive
            </mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div>
      <div>
        <span *ngIf="areArchivedBreakSchemesShown()">{{'SETTINGS.PRESENCES.BREAKSCHEME.ARCHIVED.TITLE.TEXT' |
          translate}}</span>
      </div>
      <div *ngIf="getBreakSchemesToDisplay().length > 0">
        <div>
          <div class="mat-elevation-z8" *ngFor="let breakScheme of getBreakSchemesToDisplay()">
            <div appearance="outlined" class="outline-card dynamic-card">
              <div class="outline-card-content">
                <!--<div style="position: relative; left: 2px;top:0;font-size: 0.5rem;z-index: -1;"
                   *ngIf="schemeIsUnsaved(breakScheme)">UNSAVED</div>-->
                <div class="card-wrapper">

                  <div class="card-form">
                    <div class="breakFields-wrapper">
                      <div class="card-action">

                        <mat-slide-toggle [(ngModel)]="breakScheme.isAutoEnabled"
                          (change)="breakSchemeChanged('isAutoEnabled', breakScheme)">
                          <mat-label> {{'SETTINGS.PRESENCES.AUTO.LABEL.TEXT' | translate}}</mat-label>
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="breakScheme.isDeviceEnabled"
                          (change)="breakSchemeChanged('isDeviceEnabled', breakScheme)">
                          <mat-label> {{'SETTINGS.PRESENCES.DEVICE.LABEL.TEXT' | translate}}</mat-label>
                        </mat-slide-toggle>
                      </div>
                      <mat-form-field appearance="outline">
                        <mat-label>{{'SETTINGS.PRESENCES.NAME.LABEL.TEXT' | translate}}</mat-label>
                        <input matInput [(ngModel)]="breakScheme.name">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label> {{'SETTINGS.PRESENCES.WORKERGROUPS.LABEL.TEXT' | translate}}</mat-label>
                        <mat-select [(ngModel)]="breakScheme.workerGroupIds" multiple>
                          <mat-option *ngFor="let workerGroup of allwgGroupsList" [value]="workerGroup.id">
                            {{ workerGroup.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>{{'SETTINGS.PRESENCES.TYPE.LABEL.TEXT' | translate}}</mat-label>
                        <mat-select [(ngModel)]="breakScheme.breakType">
                          <mat-option value="PAID">{{'SETTINGS.PRESENCES.PAIDTOGGLE.LABEL.TEXT' |
                            translate}}</mat-option>
                          <mat-option value="UNPAID">{{'SETTINGS.PRESENCES.UNPAIDTOGGLE.LABEL.TEXT' |
                            translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="breakFields-wrapper">
                      <mat-form-field appearance="outline" style="margin-top: 0.4rem">
                        <mat-label> {{'SETTINGS.PRESENCES.STARTTIME.LABEL.TEXT' | translate}}</mat-label>
                        <input matInput type="time" [(ngModel)]="breakScheme.startTime">
                      </mat-form-field>
                      <mat-form-field appearance="outline" style="margin-top: 0.4rem">
                        <mat-label> {{'SETTINGS.PRESENCES.ENDTIME.LABEL.TEXT' | translate}}</mat-label>
                        <input matInput type="time" [(ngModel)]="breakScheme.endTime">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label> {{'SETTINGS.PRESENCES.BREAKTIME.LABEL.TEXT' | translate}}</mat-label>
                        <input matInput type="time" [(ngModel)]="breakScheme.breakTime">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label> {{'SETTINGS.PRESENCES.DURATION.LABEL.TEXT' | translate}}</mat-label>
                        <input matInput type="number" [(ngModel)]="breakScheme.duration">
                        <small matSuffix class="mr-3">minutes</small>
                      </mat-form-field>
                    </div>
                  </div>
                  <div>
                    <button mat-icon-button color="accent" (click)="archiveBreakScheme($event, breakScheme, true)"
                      matTooltip="Archive break scheme" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below" *ngIf="!breakScheme.isArchived">
                      <mat-icon>archive</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" (click)="archiveBreakScheme($event, breakScheme, false)"
                      matTooltip="Unarchive break scheme" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below" *ngIf="breakScheme.isArchived">
                      <mat-icon>unarchive</mat-icon>
                    </button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
      <div style="margin-left: 16px; display:flex; flex-direction: column;"
        *ngIf="getBreakSchemesToDisplay().length === 0">
        <div style="min-width: 95%;">
          <div
            style="margin-left: 35%; margin-top: 1.5rem; margin-bottom: 2px; display:flex; flex-direction: column;justify-content: space-around;">
            <span *ngIf="areArchivedBreakSchemesShown()">
              {{'SETTINGS.PRESENCES.NOARCHIVED.T_CONTENT.TEXT' | translate}}
            </span>
            <span *ngIf="areUnArchivedBreakSchemesShown()">
              {{'SETTINGS.PRESENCES.NOUNARCHIVED.T_CONTENT.TEXT' | translate}}
            </span>
          </div>
        </div>

      </div>

    </div>
    <mat-card-actions align="end" style="margin-right: 1rem;">
      <button mat-stroked-button (click)="cancelClickedOnBreakSchemes()" color="primary"
        style="margin-right: 2rem;">{{'SETTINGS.CANCEL.BUTTON.TEXT' | translate}}</button>
      <button (click)="saveBreakSchemes()" mat-raised-button color="primary">{{'SETTINGS.SAVE.BUTTON.TEXT' |
        translate}}</button>
    </mat-card-actions>
  </div>

  <div class="transparent-effect-card mt-5" appearance="outlined">
    <div style="display: flex; flex-direction: column;">
      <mat-card-title style="font-size: 1.2rem;margin-bottom: 1rem;font-weight: 500;">
        {{'SETTINGS.PRESENCES.PRESENCEWATCH.TITLE.TEXT' | translate}}
      </mat-card-title>
    </div>
    <br />
    <div>
      <div style="margin-left: 16px; display:flex; flex-direction: column;">
        <div style="font-size: 1.15rem;margin-bottom: 0.6rem;font-weight: 400;">
          {{'SETTINGS.PRESENCES.BREAKWATCH.TITLE.TEXT' | translate}}
        </div>
        <div style="font-weight: 200;margin-bottom: 1rem;margin-left:8px;color: #ffffffb3;">
          {{'SETTINGS.PRESENCES.BREAKWATCH.T_CONTENT.TEXT' | translate}}
        </div>
        <mat-slide-toggle [(ngModel)]="registerWatchBreaksFlag" style="margin-bottom: 1.85rem;margin-left:8px;">
          <mat-label style="font-size: 1rem;font-weight: 300;color: #ffffffb3;">
            {{'SETTINGS.PRESENCES.BREAKWATCH.LABEL.TEXT' | translate}}
          </mat-label>
        </mat-slide-toggle>

        <div style="font-size: 1.15rem;margin-bottom: 0.6rem;font-weight: 400;">
          {{'SETTINGS.PRESENCES.CHECKINWATCH.TITLE.TEXT' | translate}}
        </div>
        <div style="font-weight: 200;margin-bottom: 1rem;max-width: 80%;margin-left:8px;color: #ffffffb3;">
          {{'SETTINGS.PRESENCES.CHECKINWATCH.T_CONTENT.TEXT' | translate}}
        </div>
        <mat-slide-toggle [(ngModel)]="registerWatchCheckInFlag" style="margin-bottom: 1.85rem;margin-left:8px;">
          <mat-label style="font-size: 1rem;font-weight: 300;color: #ffffffb3;">
            {{'SETTINGS.PRESENCES.CHECKINWATCH.LABEL.TEXT' | translate}}
          </mat-label>
        </mat-slide-toggle>


        <div style="font-size: 1.15rem;margin-bottom: 0.6rem;font-weight: 400;">
          {{'SETTINGS.PRESENCES.CHECKOUTWATCH.TITLE.TEXT' | translate}}
        </div>
        <div style="font-weight: 200;margin-bottom: 1rem;max-width: 80%;margin-left:8px;color: #ffffffb3;">
          {{'SETTINGS.PRESENCES.CHECKOUTWATCH.T_CONTENT.TEXT' | translate}}
        </div>
        <mat-slide-toggle [(ngModel)]="registerWatchCheckOutFlag" style="margin-bottom: 1rem;margin-left:8px;">
          <mat-label style="font-size: 1rem;font-weight: 300;color: #ffffffb3;">
            {{'SETTINGS.PRESENCES.CHECKOUTWATCH.LABEL.TEXT' | translate}}
          </mat-label>
        </mat-slide-toggle>
      </div>

    </div>
    <div align="end" style="margin-right: 1rem;">
      <button mat-stroked-button (click)="cancelClickedPresenceFlags()" color="primary"
        style="margin-right: 2rem;">{{'SETTINGS.CANCEL.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button (click)="savePresenceFlags()" color="primary">{{'SETTINGS.SAVE.BUTTON.TEXT' |
        translate}}</button>
    </div>
  </div>

  <div class="transparent-effect-card mt-5" appearance="outlined">
    <div style="font-size: 1.2rem; margin-bottom: 1rem; font-weight: 500;">
      <mat-card-title> {{'SETTINGS.PRESENCES.ROUNDING.TITLE.TEXT' | translate}}</mat-card-title>
    </div>
    <br />
    <div>
      <div class="rounding-content">

        <div class="rounding-text mb-3"> {{'SETTINGS.PRESENCES.ROUNDINGINTERVAL.TITLE.TEXT' | translate}}</div>
        <mat-form-field appearance="outline" class="mb-3 w-100">
          <mat-label> {{'SETTINGS.PRESENCES.INTERVAL.LABEL.TEXT' | translate}}</mat-label>
          <mat-select [(ngModel)]="roundingInterval" required (ngModelChange)="roundingIntervalChanged()">
            <mat-option value="1">1</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="12">12</mat-option>
            <mat-option value="15">15</mat-option>
            <mat-option value="20">20</mat-option>
            <mat-option value="30">30</mat-option>
            <mat-option value="60">60</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="d-flex gap-20">
          <div class="round-till-tweleve round-sections f-grow mr-2 outline-card">
            <div class="d-flex mb-3 justify-content-between">
              <div class="rounding-text mr-3"> {{'SETTINGS.PRESENCES.ROUNDINGTEXT.TITLE.TEXT' | translate}}</div>
              <div class="rounding-hint"> {{'SETTINGS.PRESENCES.TILL.TITLE.TEXT' | translate}}</div>
            </div>
            <div class="rounding-fields d-flex flex-column gap-20">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> {{'SETTINGS.PRESENCES.ROUNDINGDOWN.LABEL.TEXT' | translate}}</mat-label>
                <input matInput [(ngModel)]="rounding.roundingDown0" type="number"
                  (change)="handleRoundingDownChange('roundingDown0')" [disabled]="roundingInterval == null">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> {{'SETTINGS.PRESENCES.ROUNDINGUP.LABEL.TEXT' | translate}}</mat-label>
                <input matInput type="number" [(ngModel)]="rounding.roundingUp0" [disabled]="true">
              </mat-form-field>
            </div>

          </div>
          <div class="round-after-tweleve round-sections f-grow ml-2 outline-card">
            <div class="d-flex mb-3 justify-content-between">
              <div class="rounding-text mr-3">{{'SETTINGS.PRESENCES.ROUNDINGTEXT.TITLE.TEXT' | translate}}</div>
              <div class="rounding-hint mr-2"> {{'SETTINGS.PRESENCES.AFTER.TITLE.TEXT' | translate}}</div>

            </div>
            <div class="rounding-fields d-flex flex-column gap-20 ">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'SETTINGS.PRESENCES.ROUNDINGDOWN.LABEL.TEXT' | translate}}</mat-label>
                <input matInput [(ngModel)]="rounding.roundingDown12" type="number"
                  (change)="handleRoundingDownChange('roundingDown12')" [disabled]="roundingInterval == null">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'SETTINGS.PRESENCES.ROUNDINGUP.LABEL.TEXT' | translate}}</mat-label>
                <input matInput type="number" [(ngModel)]="rounding.roundingUp12" [disabled]="true">
              </mat-form-field>
            </div>
          </div>
        </div>



      </div>

    </div>
    <div align="end" style="margin-right: 1rem;">
      <button mat-stroked-button (click)="cancelPresenceRounding()" color="primary"
        style="margin-right: 2rem;">{{'SETTINGS.CANCEL.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button (click)="savePresenceRounding()" color="primary">{{'SETTINGS.SAVE.BUTTON.TEXT' |
        translate}}</button>
    </div>
  </div>

</div>
