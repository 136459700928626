<div class="h-100 d-flex flex-column">
  <!-- <app-sidenav-help></app-sidenav-help> -->
  <app-nav></app-nav>
  <div class="router-outlet h-100">
     <app-sidenav-help>
      <div [class]="isLoggedIn?'container':'login-container h-100'">
        <router-outlet></router-outlet>
      </div>
      
     </app-sidenav-help>
     
  </div>

</div>
