<div style="min-width: 400px;max-width: 520px;">
  <h2 mat-dialog-title> {{'SETTINGS.VARIETY.MODAL.EDIT.TITLE.TEXT' | translate}}</h2>

  <mat-dialog-content [formGroup]="editVarietyForm">
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
        <mat-form-field class="w-100 mt-3" appearance="outline">
          <mat-label>{{'SETTINGS.MODAL.PERFNORM.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="performanceNorm">
        </mat-form-field>
        <div class="w-100 my-3">
          <mat-form-field class="w-100">
            <mat-label>{{'SETTINGS.MODAL.LABELS.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="labelIds" multiple>
              <mat-option *ngFor="let label of labelsList" [value]="label.id">{{label.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <small class="clr-primary worker-group" (click)="openAddLabel()">{{'SETTINGS.MODAL.ADDLABEL.BUTTON.TEXT' | translate}}</small>
        </div>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{'SETTINGS.MODAL.EXTERNALID.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="externalId">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <br />
  <div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateVariety()" style="margin-left: 20px;">
        {{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
