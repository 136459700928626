<div class="page-top-space">
  <mat-card class="page-main-card">
    <mat-card-content>
      <div class="d-flex align-item-center page-action">
        <nav class="tabs-selection" mat-tab-nav-bar mat-align-tabs="center"
        [tabPanel]="tabPanel">
          <a mat-tab-link label="PRESENCES" (click)="tabIndex = -1"
             [routerLink]="'/dashboard/labor/presences'"
             [active]="tabIndex === -1">
             {{'DASHBOARD.LABOR.TABS.PRESENCES.LABEL.TEXT' |  translate}} 
          </a>
          <a mat-tab-link label="OVERVIEW" (click)="tabIndex = 0"
             [routerLink]="'/dashboard/labor/overview'"
             [active]="tabIndex === 0">
             {{'DASHBOARD.LABOR.TABS.OVERVIEW.LABEL.TEXT' |  translate}} 
          </a>
          <a mat-tab-link label="TROLLEY" (click)="tabIndex = 1"
             [routerLink]="'/dashboard/labor/trolley'"
             [active]="tabIndex === 1">
             {{'DASHBOARD.LABOR.TABS.TROLLEY.LABEL.TEXT' |  translate}} 
          </a>
          <a mat-tab-link label="PERFORMANCE" (click)="tabIndex = 2"
             [routerLink]="'/dashboard/labor/performance'"
             [active]="tabIndex === 2">
             {{'DASHBOARD.LABOR.TABS.PERFORMANCE.LABEL.TEXT' |  translate}} 
          </a>
          <a mat-tab-link label="PRODUCTIVITY" (click)="tabIndex = 3"
             [routerLink]="'/dashboard/labor/productivity'"
             [active]="tabIndex === 3">
             {{'DASHBOARD.LABOR.TABS.PRODUCTIVITY.LABEL.TEXT' |  translate}} 
          </a>
        </nav>
      </div>
      
      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
 

</div>
