
<div class="performance-actions">
  <app-date-selection></app-date-selection>

  <div class="sort-dropdown-wrapper"
       [style.display]="isRowChartDisplayed()">

    <mat-form-field class="sort-dropdown-formfield" appearance="outline">
      <mat-icon matPrefix>sort</mat-icon>
      <mat-select [(value)]="rowSortOption"
                (selectionChange)="rowSortOptionChanged()"
                style="margin-left: 0.4rem;"
                [disabled]="(!prdctvtyRowData || (prdctvtyRowData.length === 0))">
                <mat-option value="AZ"><span innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span></mat-option>
                <mat-option value="ZA"><span innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span></mat-option>
                <mat-option value="HL"><span innerHTML="{{'DASHBOARD.PAGESORT.NETPERFORMACE.HIGHLOW.LABEL.TEXT' |  translate}}"></span></mat-option>
                <mat-option value="LH"><span innerHTML="{{'DASHBOARD.PAGESORT.NETPERFORMACE.LOWHIGH.LABEL.TEXT' |  translate}}"></span></mat-option>
    </mat-select>
    </mat-form-field>
  </div>
  <span class="f-grow"></span>
  <div>
    <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [(ngModel)]="rowChartVsTable">
      <mat-button-toggle value="chart" aria-label="Text align left">
        <mat-icon class="material-symbols-outlined"
          matTooltip="{{'DASHBOARD.PAGETOGGLE.SEECHART.LABEL.TEXT' |  translate}}"
          matTooltipClass="tooltip-class"
          matTooltipHideDelay="100"
          matTooltipPosition="below"
        >bar_chart
        </mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="table" aria-label="Text align center">
        <mat-icon class="material-symbols-outlined"
          matTooltip="{{'DASHBOARD.PAGETOGGLE.SEETABLE.LABEL.TEXT' |  translate}}"
          matTooltipClass="tooltip-class"
          matTooltipHideDelay="100"
          matTooltipPosition="below"
        >table_chart
        </mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<div class="table-card">
  <div class="preformance-info">
    <div *ngIf="prdctvtyRowData?.length > 0">
        <span><span class="cstm-color">{{'DASHBOARD.PRODUCTIVITY.STATUS.FININSHED.LABEL.TEXT' |  translate}}</span> {{countData.finished.count}} {{'DASHBOARD.PRODUCTIVITY.STATUS.PICKED.LABEL.TEXT' |  translate}}, {{countData.finished.rows}} {{'DASHBOARD.PRODUCTIVITY.STATUS.ROWS.LABEL.TEXT' |  translate}}</span> <br/>
        <span><span class="cstm-color">{{'DASHBOARD.PRODUCTIVITY.STATUS.ACTUAL.LABEL.TEXT' |  translate}}</span> {{countData.totalAll.count}} {{'DASHBOARD.PRODUCTIVITY.STATUS.PICKED.LABEL.TEXT' |  translate}}, {{countData.totalAll.rows}} {{'DASHBOARD.PRODUCTIVITY.STATUS.ROWS.LABEL.TEXT' |  translate}}</span> <br/>
    </div>
    <mat-icon
      matTooltip="{{'DASHBOARD.TOOLTIP.DOWNLOADCSV.LABEL.TEXT' |  translate}}"
      matTooltipClass="tooltip-class"
      matTooltipHideDelay="100"
      matTooltipPosition="below"
      (click)="downloadAsCSV('Rows')"
      *ngIf="prdctvtyRowData.length > 0">download
    </mat-icon>
  </div>

  <div [style.display]="isRowChartDisplayed()"
     [style.height.px]="graphHeight"
     *ngIf="prdctvtyRowData?.length > 0">
  <ngx-charts-bar-horizontal-stacked
    [view]=""
    [scheme]="colorScheme"
    [results]="prdctvtyRowData"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [legendTitle]="legendTitle"
    [roundDomains]="roundDomains"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [showGridLines]="false"
    [showDataLabel]="showDataLabel"
    [animations]="chartAnimation"
    (select)="onSelect($event)"
    style="fill: #F3F3F3;" *ngIf="[prdctvtyRowData]">
    <ng-template #tooltipTemplate let-model="model">
      <div class="tooltip" style='text-align: left;' [innerHTML]="getToolTip(model) | safeHtml">
      </div>
    </ng-template>
    >
  </ngx-charts-bar-horizontal-stacked>
</div>

<section class="listing-container-prdctvty table-styled" style="clear: both;" [style.display]="isRowTableDisplayed()">
  <table
    mat-table
    [dataSource]="rowsDataSource"
    multiTemplateDataRows
    matSort
    class="listing-table"
  >
    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of rowsColumnsToDisplay"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ prdctvtyAttributesReadableMap.get(column) | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="25"
    [showFirstLastButtons]="true"
  ></mat-paginator>
</section>
<div
  style="margin-top: 6rem; padding-top: 2rem; text-align: center; clear: both;font-size: 1.2rem;overflow: hidden;"
  [style.height.px]="graphHeight" *ngIf="(prdctvtyRowData?.length === 0)">
  <span innerHTML="{{'DASHBOARD.TABEL.SELECTEDDATE.NODATA.CONTENT.TEXT' | translate}}"></span>
</div>
</div>


